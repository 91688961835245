import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

// import { hasAuthTokenAsync } from '../../../../lib'

// 3rd Party Components
import FontAwesome from 'react-fontawesome'

// Components
import {
  Tr,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Selector,
  Table,
  SummaryItem,
} from '../../../common'

// Actions
// import {
//   getTeamMembers,
//   filterTeamMembers,
// } from '../../../../actions/main'

// import {
//   setMember,
//   getTeam
// } from '../../../../actions/team'

import './job-table.css'
import { hasAuthTokenAsync } from '../../../lib';
import { api } from '../../../config';
import Cookies from 'js-cookie';

class JobTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchType: '',
      search: '',
      jobs: []
    }
  }

  componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')):{ user: '' };
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      hasAuthTokenAsync()
      .then(() => {
        api.get(`/job/${id}`)
        .then(data => this.setState({ jobs: data }))
        .catch(err => err);
      }).catch(err => err);
    })
  }

  filterUsers(search) {
    const { searchType } = this.state
    // this.props.filterTeamMembers(search, searchType)
    this.setState({ search, searchType })
  }

  view(e, job) {
    e.stopPropagation()
    this.props.setJob(job)
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Title
          </th>
          <th>
            Category
          </th>
          <th>
            Duration
          </th>
          <th>
            Budget
          </th>
          <th>
            Age
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  renderTableBody() {
    // console.log(this.state.jobs)
    return (
      <tbody>
        { this.state.jobs.map(this.renderTableRow.bind(this)) }
      </tbody>
    )
  }

  renderTableRow(job) {
    const link = `/viewjobs/${job.id}`
    const date = moment(job.created_at).format('MM/DD/YYYY')
    const a = moment(Date.now())
    const b = moment(job.created_at)
    const age = a.diff(b, 'days')
    // console.log(age)
    return (
      <Tr
        to={link}
        key={job.id}
      >
        <td className="bold">
          {job.title}
        </td>
        <td>
          {job.jobType}
        </td>
        <td>
          {job.duration}
        </td>
        <td>
          {job.initialPrice}
        </td>
        <td>
          {age || '...'}
        </td>
        <td className="detailsCell">
          <Button
            title="DETAILS"
            link={link}
            onClick={e => this.viewJob(e, job)}
            // inactive
          />
        </td>
      </Tr>
    )
  }

  renderTable() {
    return (
      <Table className="table" hoverable>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  get placeholderText() {
    switch (this.state.searchType) {
      case 'name':
        return 'Search By First or Last Name...'
      case 'email':
        return 'Search By Email...'
      default:
        return 'Search By Name or Email...'
    }
  }

  get searchOptions() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'email',
        display: 'Email',
      },
    ]
  }


  render() {
    const { searchType } = this.state;
    const { tBonus, weeklyHours, lastLogin } = this.props;
    const items = [
      {
        title: 'Credits',
        content: tBonus || '...',
      },
      // {
      //   title: 'Hours Online Weekly',
      //   content: weeklyHours || '...',
      // },
      {
        title: 'Last Logged In',
        content: lastLogin || '...',
      },
    ]
    // const link = Object.keys(this.props.account.dormant).length === 0 ? '/bank/payment' : '/bank/add-credits'
    const link = this.state.account === true ? '/bank/payment' : '/bank/add-credits'
    return (
      <div className="app">
          <ActionBox className="ActionBox">
            <div className="main">
            </div>
            <div className="summary">
            <SearchBar
              options={this.searchOptions}
              selected={searchType}
              onSelect={searchType => this.setState({ searchType })}
              label="Search"
              placeholder={this.placeholderText}
              value={this.state.search}
              onChange={search => this.setState({ search })}
            />
            </div>
            <div className="action">
              <Button
                link='/viewjobs/createjob'
                icon="plus"
                title="ADD JOB"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
      </div>
    );
  }
}

const mapStateToProps = () => {
  // const {
  //   authDisplay,
  //   plaid,
  //   error,
  // } = accounts

  // const {
  //   isAdmin,
  // } = auth

  // return {
  //   auth: authDisplay,
  //   plaid,
  //   error,
  //   isAdmin,
  // }
}

const actions = { 
  // logout, 
  // getAccounts, 
  // addAccount 
}

// export default connect(mapStateToProps, actions)(JobTable);
export default JobTable;
