/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../../config'

export default {
  post(data) {
    const url = '/third-party'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/third-party/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/third-party/${id}`
    return api.patch(url, update)
  },
  deleteById(data) {
    const url = '/user/login'
    return api.delete(url, data)
  }
}
