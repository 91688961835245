// USER FLOW
export const CREATE_COMPANY = 'create_company'
export const CREATE_COMPANY_SUCCESS = 'create_company_success'
export const CREATE_COMPANY_FAILURE = 'create_company_failure'

export const SEND_SMS = 'send_sms'
export const SEND_SMS_SUCCESS = 'send_sms_success'
export const SEND_SMS_FAILURE = 'send_sms_failure'

export const SET_COMPANY = 'set_company'

export const GET_COMPANY = 'get_company'
export const GET_COMPANY_SUCCESS = 'get_company_success'
export const GET_COMPANY_FAILURE = 'get_company_failure'

export const PATCH_COMPANY = 'patch_company'
export const PATCH_COMPANY_SUCCESS = 'patch_company_success'
export const PATCH_COMPANY_FAILURE = 'patch_company_failure'

export const CLEAR_STATE = 'company_clear_state'

// AUTH FLOW
export const EMAIL_CHANGED = 'auth_email_changed'
export const ACCESS_CHANGED = 'auth_access_changed'
export const PASSWORD_CHANGED = 'auth_password_changed'
export const CONFIRM_PASSWORD_CHANGED = 'auth_confirm_password_changed'
export const USERNAME_CHANGED = 'auth_username_changed'

export const LOGIN = 'auth_login'
export const LOGIN_FAILURE = 'auth_login_failure'
export const LOGIN_SUCCESS = 'auth_login_success'

export const GET_SELF = 'get_self'
export const GET_SELF_FAILURE = 'get_self_failure'
export const GET_SELF_SUCCESS = 'get_self_success'

export const PATCH_SELF = 'patch_self'
export const PATCH_SELF_FAILURE = 'patch_self_failure'
export const PATCH_SELF_SUCCESS = 'patch_self_success'

export const RESET_PASSWORD = 'reset-password'
export const RESET_PASSWORD_FAILURE = 'reset-password_failure'
export const RESET_PASSWORD_SUCCESS = 'reset-password_success'

export const CREATE_PASSWORD = 'create-password'
export const CREATE_PASSWORD_FAILURE = 'create-password_failure'
export const CREATE_PASSWORD_SUCCESS = 'create-password_success'

export const CLAIM_ACCOUNT = 'auth_claim_account'

export const CLEAR_ERRORS = 'auth_clear_errors'
