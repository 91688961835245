// TEAM FLOW
export const CREATE_STRIPE = 'create_stripe'
export const CREATE_STRIPE_SUCCESS = 'create_stripe_success'
export const CREATE_STRIPE_FAILURE = 'create_stripe_failure'

export const SET_STRIPE = 'set_stripe'

export const GET_STRIPE = 'get_stripe'
export const GET_STRIPE_SUCCESS = 'get_stripe_success'
export const GET_STRIPE_FAILURE = 'get_stripe_failure'

export const GET_PLAN = 'get_plan'
export const GET_PLAN_SUCCESS = 'get_plan_success'
export const GET_PLAN_FAILURE = 'get_plan_failure'

export const PATCH_STRIPE = 'patch_stripe'
export const PATCH_STRIPE_SUCCESS = 'patch_stripe_success'
export const PATCH_STRIPE_FAILURE = 'patch_stripe_failure'

export const CLEAR_ERRORS = 'stripe_clear_errors'
export const CLEAR_STATE = 'stripe_clear_state'
