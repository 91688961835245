// Router
import { push } from 'react-router-redux'

// Types
import * as jobTypes from "./job-types"

// Services
import jobs from './job-service'

// Helpers
import { updateItemWithArray, updateArrayWithItem } from '../../../lib'

import * as cookies from 'js-cookie'

const {
  SET_JOB,
  GET_JOB,
  GET_JOB_SUCCESS,
  GET_JOB_FAILURE,

  GET_MY_JOBS,
  GET_MY_JOBS_FAILURE,
  GET_MY_JOBS_SUCCESS,

  GET_BULK_PATCH,
  GET_BULK_PATCH_SUCCESS,
  GET_BULK_PATCH_FAILURE,

  GET_GENDER,
  GET_GENDER_FAILURE,
  GET_GENDER_SUCCESS,

  GET_LENGTH,
  GET_LENGTH_FAILURE,
  GET_LENGTH_SUCCESS,

  GET_SIZE,
  GET_SIZE_FAILURE,
  GET_SIZE_SUCCESS,

  ADD_QUANTITIES,
  ADD_QUANTITIES_SUCCESS,
  ADD_QUANTITIES_FAILURE,

  SUB_QUANTITIES,
  SUB_QUANTITIES_SUCCESS,
  SUB_QUANTITIES_FAILURE,

  CREATE_LISTING,
  CREATE_LISTING_FAILURE,
  CREATE_LISTING_SUCCESS,

  PATCH_PRODUCT,
  PATCH_PRODUCT_SUCCESS,
  PATCH_PRODUCT_FAILURE,

  DELETE_PRODUCT,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  
  SET_CHECKED_PRODUCTS,
  FILTER_PRODUCTS,
  FILTER_SUCCESS,
  FILTER_FAILURE,
    
} = jobTypes;

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

export const setJob = payload => {
  return {
    type: SET_JOB,
    payload,
  }
}

export const getJobById = id => dispatch => {
  // check if a action has been set yet
  // const { action } = getState().actions
  // if (action && action.id !== actionId) {
  //   // clear it if it is not the same one
  //   dispatch({
  //     type: CLEAR_STATE,
  //   })
  // }

  dispatch({
    type: GET_JOB,
  })
  jobs.getJobById(id)
    .then(res => onGetJobByIdSuccess({dispatch, res}))
    .catch(err => onGetJobByIdFailure({dispatch, err}))
}

function onGetJobByIdSuccess({dispatch, res}) {
  dispatch({
    type: GET_JOB_SUCCESS,
    payload: res,
  })
}

function onGetJobByIdFailure({dispatch, err}) {
  const payload = err.message || 'GetJobById Failed'
  window.alert(payload)
  dispatch({
    type: GET_JOB_FAILURE,
    payload,
  })
  dispatch(push('/viewjobs'))
}

export const addQuantities = () => dispatch => {
  dispatch({
    type: ADD_QUANTITIES,
  })
  jobs.getJobsById()
    .then(res => onAddQuantitesSuccess({dispatch, res}))
    .catch(err => onAddQuantitiesFailure({dispatch, err}))
}

function onAddQuantitesSuccess({dispatch, res}) {
  console.log('addQuantites success');
  dispatch({
    type: ADD_QUANTITIES_SUCCESS,
    payload: res,
  })
}

function onAddQuantitiesFailure({dispatch, err}) {
  console.log('addQuantities failure', err);
  const payload = err.message || 'addQuantities Failed'
  window.alert(payload)
  dispatch({
    type: ADD_QUANTITIES_FAILURE,
    payload: err,
  })
}

export const subQuantities = () => dispatch => {
  dispatch({
    type: SUB_QUANTITIES,
  })
  jobs.getJobsById()
    .then(res => onSubQuantitesSuccess({dispatch, res}))
    .catch(err => onSubQuantitiesFailure({dispatch, err}))
}

function onSubQuantitesSuccess({dispatch, res}) {
  console.log('subQuantites success');
  dispatch({
    type: SUB_QUANTITIES_SUCCESS,
    payload: res,
  })
}

function onSubQuantitiesFailure({dispatch, err}) {
  console.log('addQuantities failure', err);
  const payload = err.message || 'subQuantities Failed'
  window.alert(payload)
  dispatch({
    type: SUB_QUANTITIES_FAILURE,
    payload: err,
  })
}


export const getJobsById = payload => dispatch => {
  dispatch({
    type: GET_MY_JOBS,
  })
  jobs.getJobsById(payload)
    .then(res => onGetJobsByIdSuccess({dispatch, res}))
    .catch(err => onGetJobsByIdFailure({dispatch, err}))
}
  
  function onGetJobsByIdSuccess({dispatch, res}) {
    console.log('getJobs success');
    dispatch({
      type: GET_MY_JOBS_SUCCESS,
      payload: res.data,
    })
  }
  
  function onGetJobsByIdFailure({dispatch, err}) {
    console.log('getJobs failure', err);
    const payload = err.message || 'getJobs Failed'
    window.alert(payload)
    dispatch({
      type: GET_MY_JOBS_FAILURE,
      payload,
    })
  }
  
  // export const getGender = () => dispatch => {
  //   dispatch({
  //     type: GET_GENDER,
  //   })
  //   jobs.getGender()
  //     .then(res => onGetGenderSuccess({dispatch, res}))
  //     .catch(err => onGetGenderFailure({dispatch, err}))
  // }
  
  // function onGetGenderSuccess({dispatch, res}) {
  //   console.log('getGender success');
  //   dispatch({
  //     type: GET_GENDER_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetGenderFailure({dispatch, err}) {
  //   console.log('getGender failure', err);
  //   const payload = err.message || 'getGender Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_GENDER_FAILURE,
  //     payload,
  //   })
  // }

  // export const getLength = () => dispatch => {
  //   dispatch({
  //     type: GET_LENGTH,
  //   })
  //   jobs.getLength()
  //     .then(res => onGetLengthSuccess({dispatch, res}))
  //     .catch(err => onGetLengthFailure({dispatch, err}))
  // }
  
  // function onGetLengthSuccess({dispatch, res}) {
  //   console.log('getLength success');
  //   dispatch({
  //     type: GET_LENGTH_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetLengthFailure({dispatch, err}) {
  //   console.log('getLength failure', err);
  //   const payload = err.message || 'getLength Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_LENGTH_FAILURE,
  //     payload,
  //   })
  // }

  // export const getSize = () => dispatch => {
  //   dispatch({
  //     type: GET_SIZE,
  //   })
  //   jobs.getSize()
  //     .then(res => onGetSizeSuccess({dispatch, res}))
  //     .catch(err => onGetSizeFailure({dispatch, err}))
  // }
  
  // function onGetSizeSuccess({dispatch, res}) {
  //   console.log('getSize success');
  //   dispatch({
  //     type: GET_SIZE_SUCCESS,
  //     payload: res,
  //   })
  // }
  
  // function onGetSizeFailure({dispatch, err}) {
  //   console.log('getSize failure', err);
  //   const payload = err.message || 'getSize Failed'
  //   window.alert(payload)
  //   dispatch({
  //     type: GET_SIZE_FAILURE,
  //     payload,
  //   })
  // }
  
  export const bulkPatchProducts = update => (dispatch, getState) => {
    dispatch({
      type: GET_BULK_PATCH,
    })
    jobs.patchById(update)
      .then(res => onBulkPatchProductsSuccess({dispatch, getState, res}))
      .catch(err => onBulkPatchProductsFailure({dispatch, err}))
  }
  
  function onBulkPatchProductsSuccess({dispatch, getState, res}) {
    console.log('BulkPatchProducts success');
    const { products, productsDisplay } = getState().product
  
    const updateProduct = product => updateItemWithArray(product, res)
  
    dispatch({
      type: GET_BULK_PATCH_SUCCESS,
      payload: jobs.map(updateProduct),
    })
    dispatch({
      type: FILTER_PRODUCTS,
      payload: productsDisplay.map(updateProduct),
    })
  }
  
  function onBulkPatchProductsFailure({dispatch, err}) {
    console.log('BulkPatchProducts failure', err);
    const payload = err.message || 'BulkPatchProducts Failed'
    window.alert(payload)
    dispatch({
      type: GET_BULK_PATCH_FAILURE,
      payload,
    })
  }
  
  export const createJobListing = data => (dispatch, getState) => {
    dispatch({
      type: CREATE_LISTING,
    })
    jobs.post(data)
      .then(res => onCreateListingSuccess({dispatch, getState, res}))
      .catch(err => onCreateListingFailure({dispatch, err}))
  }
  
  function onCreateListingSuccess({dispatch, getState, res}) {
    console.log('createListing success');
    // const { products } = getState()
    dispatch({
      type: CREATE_LISTING_SUCCESS,
      payload: res
    })
    dispatch(push('/products'))
  }
  
  function onCreateListingFailure({dispatch, err}) {
    console.log('createListing failure', err);
    const payload = err.message || 'createListing Failed'
    window.alert(payload)
    dispatch({
      type: CREATE_LISTING_FAILURE,
      payload,
    })
  }
  
  export const patchProductById = (id, update) => (dispatch, getState) => {
    dispatch({
      type: PATCH_PRODUCT,
    })
    jobs.patchById(id, update)
      .then(res => onPatchProductSuccess({dispatch, getState, res}))
      .catch(err => onPatchProductFailure({dispatch, err}))
  }
  
  export const patchProductStatus = update => (dispatch, getState) => {
    dispatch({
      type: PATCH_PRODUCT,
    })
    jobs.patchStatus(update)
      .then(res => onPatchProductSuccess({dispatch, getState, res}))
      .catch(err => onPatchProductFailure({dispatch, err}))
  }
  
  function onPatchProductSuccess({dispatch, getState, res}) {
    console.log('patchProduct success');
    let { products, productsDisplay } = getState().products
    dispatch({
      type: PATCH_PRODUCT_SUCCESS,
      payload: updateArrayWithItem(jobs.products, res)
    })
    dispatch({
      type: FILTER_PRODUCTS,
      payload: updateArrayWithItem(productsDisplay.products, res),
    })
  }
  
  function onPatchProductFailure({dispatch, err}) {
    console.log('patchProduct failure', err)
    const payload = err.message || 'PatchProduct Failed'
    window.alert(payload)
    dispatch({
      type: PATCH_PRODUCT_FAILURE,
      payload,
    })
  }
  
  export const deleteProductById = id => (dispatch, getState) => {
    dispatch({
      type: DELETE_PRODUCT,
    })
    jobs.deleteById(id)
      .then(res => onDeleteProductSuccess({dispatch, getState}))
      .catch(err => onDeleteProductFailure({dispatch, err}))
  }
  
  // TODO: REFACTOR - HOT RELOAD
  function onDeleteProductSuccess({dispatch, getState}) {
    console.log('DeleteProduct success');
    const product = getState().jobs.jobs.products
    // const products = product.filter(el => el.id !== product.id)
  
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: product,
    })
  }
  
  function onDeleteProductFailure({dispatch, err}) {
    console.log('DeleteProduct failure', err)
    const payload = err.message || 'DeleteProduct Failed'
    window.alert(payload)
    dispatch({
      type: DELETE_PRODUCT_FAILURE,
      payload,
    })
  }
  
  // export const filterProductsByStatus = () => (dispatch, getState) => {
  //   const { barcode } = getState().jobs.barcode
  //   const { products } = getState().products 
  //   const payload = barcode ? (
  //     jobs.filter(el => el.barcode === barcode)
  //   ) : (
  //     [...products]
  //   )
  //   dispatch({
  //     type: FILTER_PRODUCTS,
  //     payload
  //   })
  // }

  export const filterProductsByStatus = (search, searchType) => (dispatch, getState) => {
    console.log(search)
    dispatch({
      type: FILTER_PRODUCTS,
    })
    jobs.filterProductsByStatus(search)
      .then(res => onFilterSuccess({dispatch, getState, res}))
      .catch(err => onFilterFailure({dispatch, err})) 
  }

  function onFilterSuccess({dispatch, getState, res}) {
    console.log('search success');
    let { productsDisplay } = getState().product
    dispatch({
      type: FILTER_SUCCESS,
      payload: (productsDisplay, res),
    })
  }
  
  function onFilterFailure({dispatch, err}) {
    console.log('search failure', err);
    const payload = err.message || 'search failed'
    window.alert(payload)
    dispatch({
      type: FILTER_FAILURE,
      payload,
    })
  }

  export const checkProduct = ({id}) => (dispatch, getState) => {
    const { checkedProducts } = getState().product
    let payload = []
    if (checkedProducts.includes(id)) {
      payload = checkedProducts.filter(el => el !== id)
    } else {
      payload = [...checkedProducts, id]
    }
  
    dispatch({
      type: SET_CHECKED_PRODUCTS,
      payload,
    })
  }