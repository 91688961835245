// TEAM FLOW
export const GET_ACCOUNTS = 'main_get_accounts'
export const GET_ACCOUNTS_SUCCESS = 'main_get_accounts_success'
export const GET_ACCOUNTS_FAILURE = 'main_get_accounts_failure'
export const FILTER_ACCOUNTS = 'main_filter_accounts'

export const FUND_ACCOUNT = 'fund_account'
export const FUND_ACCOUNT_SUCCESS = 'fund_account_success'
export const FUND_ACCOUNT_FAILURE = 'fund_account_failure'

export const CREATE_ACCOUNT = 'create_account'
export const CREATE_ACCOUNT_SUCCESS = 'create_account_success'
export const CREATE_ACCOUNT_FAILURE = 'create_account_failure'

export const DELETE_ACCOUNT = 'delete_account'
export const DELETE_ACCOUNT_SUCCESS = 'delete_account_success'
export const DELETE_ACCOUNT_FAILURE = 'delete_account_failure'

export const SET_ACCOUNT = 'set_account'

export const GET_ACCOUNT = 'get_account'
export const GET_ACCOUNT_SUCCESS = 'get_account_success'
export const GET_ACCOUNT_FAILURE = 'get_account_failure'

export const PATCH_ACCOUNT = 'patch_account'
export const PATCH_ACCOUNT_SUCCESS = 'patch_account_success'
export const PATCH_ACCOUNT_FAILURE = 'patch_account_failure'

export const GET_TRANSACTIONS = 'get_transaction'
export const GET_TRANSACTIONS_SUCCESS = 'get_transaction_success'
export const GET_TRANSACTIONS_FAILURE = 'get_transaction_failure'

export const CREATE_TRANSACTION = 'create_transaction'
export const CREATE_TRANSACTION_SUCCESS = 'create_transaction_success'
export const CREATE_TRANSACTION_FAILURE = 'create_transaction_failure'


export const CLEAR_ERRORS = 'account_clear_errors'
export const CLEAR_STATE = 'account_clear_state'
