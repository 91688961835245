import React, { Component } from 'react';
import { connect } from 'react-redux'

import moment from 'moment'
import { useTimer } from 'react-timer-hook';
import OtpInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// Components
import {
  Span,
  Input,
  Selector,
  FormModal,
} from '../../common'

// actions 

import styles from './VerifyPhoneModal.css'
import { hasAuthTokenAsync } from '../../lib';
import { Button, Container, Grid, Typography } from '@mui/material';

function MyTimer({ expiryTimestamp, onSubmit }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

  return (
    <div style={{textAlign: 'center'}}>
      <Button
        type='button'
        variant="contained"
        color="primary"
        disabled={isRunning}
        style={{ width: '50%', margin: '2vh 25%', justifyContent: 'space-evenly', alignContent: 'center' }}
        onClick={() => {
          // Restarts to 2 minutes timer
          const time = new Date();
          time.setSeconds(time.getSeconds() + 120);
          restart(time);
          onSubmit()
        }}
      >
        <span>{minutes}</span>:<span>{seconds}</span>
        Resend Code
      </Button>
    </div>
  );
}

class VerifyPhoneModal extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }
  componentDidMount() {
    // console.log("mounting")
    // hasAuthTokenAsync()
    // .then(() => {
      // this.props.getReps()
      // this.props.getSelf()
      // this.props.getSupervisors()
    // })
  }
  get initialState() {
    const date = moment().add(1, 'day').format('YYYY-MM-DD')
    const initialState = {
      otp: 0,
      showOtpInput: false,
      phoneNumber: ''
    };
    return initialState
  }

  static getDerivedStateFromProps(newProps) {
    return newProps.content || null
  }

  get inactive() {
    const {
      date,
      time,
      supervisor,
    } = this.state
    const rep = this.props.isadmin ? this.state.rep : this.props.me
    return !rep || !date || !time || !supervisor
  }

  submit(e) {
    e.preventDefault();
    localStorage.setItem('otp', this.state.otp);
    this.props.onSubmit();
    this.props.onClickAway();
  }

  handlePhoneNumberChange = (value) => {
    localStorage.setItem('phone', value);
  };

  handleOtpChange = (otp) => {
    this.setState({ otp });
  };

  handleVerify = () => {
    this.props.action();
    // For simplicity, let's just show the OTP input after clicking the verify button.
    this.setState({ showOtpInput: true });
  };

  handleVerifyOtp = () => {
    // Here, you can implement your logic for verifying the OTP with the server.
    // For simplicity, let's just show an alert with the success message.
    // console.log('OTP verified successfully!');
    alert('OTP verified successfully!');
  };

  render() {
    const {
      content,
      onClickAway,
    } = this.props

    const { phoneNumber, otp, showOtpInput } = this.state;
    const time = new Date();
    time.setSeconds(time.getSeconds() + 120); // 10 minutes timer
    return (
      <FormModal
        title="Phone Verification"
        onClickAway={onClickAway}
        visible={!!content}
        className={styles.modal}
        onSubmit={this.submit.bind(this)}
      >
          <div>
            <Typography variant="h6" align="center">
              Add Country Code
            </Typography>
            {!showOtpInput ? (
              <Grid>
                <Grid item>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={this.props.joinable.phoneNumber || this.props.phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    country={'us'}
                  />
                </Grid>
                <Grid>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    style={{ margin: '2vh' }}
                    onClick={this.handleVerify}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div className={styles.verifyPhone}>
                <Typography variant="body1" align="center">
                  Code sent to {localStorage.getItem('phone')}:
                </Typography>
                <div className='otp-input-container'>
                  <OtpInput
                    value={otp}
                    onChange={this.handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{ justifyContent: 'center', alignItems: 'center' }}
                    inputStyle={{ fontSize: '7vh', margin: 'auto 1vh' }}
                  />
                </div>
                <Button
                  type='submit'
                  variant="contained"
                  color="primary"
                  style={{ margin: '2vh 38%', justifyContent: 'center', alignContent: 'center' }}
                >
                  Verify
                </Button>
                <MyTimer onSubmit={this.handleVerify} expiryTimestamp={time} />
              </div>
            )}
          </div>
      </FormModal>
    );
  }
}

// const mapStateToProps = ({}) => {
  // const {
  //   smsResponse
  // } = signinReducer
  // if (smsResponse) return {
  //    smsResponse
  // }
// }

// const actions = {
// }

export default connect()(VerifyPhoneModal);
