// JoinPortal.js

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as jwt from 'jsonwebtoken';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
} from '@mui/material';

import {
  login,
  sendSMS,
  createCompany,
  emailChanged,
  passwordChanged,
} from '../redux/signin-action';

import './join-portal.css';
import { VerifyPhoneModal } from '../../../shared';

class JoinPortal extends Component {
  state = {
    userType: 'employer',
    // userType: localStorage.getItem("portal"),
    companyName: '',
    fullName: '',
    email: localStorage.getItem("email"),
    phoneNumber: '',
    experience: '',
    visitModal: null,
  };

  handleUserTypeChange = (event) => {
    this.setState({ userType: event.target.value });
  };


  handleFullNameChange = (event) => {
    this.setState({ userType: event.target.value });
  };


  handleEmailChange = (event) => {
    this.setState({ userType: event.target.value });
  };


  handlePhoneNumberChange = (event) => {
    this.setState({ userType: event.target.value });
  };


  handleExperienceChange = (event) => {
    this.setState({ userType: event.target.value });
  };

  handlePhoneVerification = (e) => {
    e.preventDefault();
    localStorage.setItem('phone', this.state.phoneNumber)
    this.setState({ visitModal: {} })
  }

  handleFormSubmit = () => {
    const {
      userType,
      companyName,
      fullName,
      email,
      experience
    } = this.state;
    // Save user information to the database
    let uType = userType === 'employer' ? 'employer' : 'freelancer';
    const data = {
      phoneNumber: encodeURIComponent(localStorage.getItem('phone')),
      email,
      userType: uType,
      company: companyName,
      fullName,
      password: encodeURIComponent(localStorage.getItem('password')),
      experience,
      deviceToken: encodeURIComponent(localStorage.getItem('otp'))
    }
    const signed = jwt.sign(JSON.stringify(data), 'J08450UND!', { algorithm: 'HS512' })
    this.props.createCompany(signed);
  };

  render() {
    const { userType, companyName, fullName, email, phoneNumber, experience } = this.state;
    return (
      <div>
        {/* Modal */}
        <VerifyPhoneModal
          content={this.state.visitModal}
          onClickAway={() => this.setState({visitModal: null})}
          joinable={this.state}
          action={() => this.props.sendSMS()}
          onSubmit={this.handleFormSubmit}
        />
        <Box className="joinPortal">
          <Typography variant="h5">Joining Application</Typography>
          <form onSubmit={this.handlePhoneVerification}>
            <FormControl component="fieldset">
              <Typography variant="subtitle1">Are you an Employer or Freelancer?</Typography>
              <RadioGroup value={userType} onChange={this.handleUserTypeChange}>
                <FormControlLabel value="employer" control={<Radio />} label="Employer" />
                {/* <FormControlLabel value="freelancer" control={<Radio />} label="Freelancer" /> */}
              </RadioGroup>
            </FormControl>

            {userType === 'employer' && (
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => this.setState({ companyName: e.target.value })}
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
            )}

            <TextField
              label="Full Name"
              value={fullName}
              onChange={(e) => this.setState({ fullName: e.target.value })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
            />

            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
            />

            <TextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <TextField
              label="Experience (in years)"
              type="number"
              value={experience}
              onChange={(e) => this.setState({ experience: e.target.value })}
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <Button type="submit" variant="contained" color="primary" className="submitButton">
              Submit
            </Button>
          </form>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = ({ signinReducer }) => {
  const {
    email,
    password,
    error,
    loading,
  } = signinReducer
  return {
    email,
    password,
    error,
    loading,
  }
}

const actions = {
  login,
  sendSMS,
  createCompany,
  emailChanged,
  passwordChanged,
}

export default connect(mapStateToProps, actions)(JoinPortal);
