import React from "react";
import { Routes, Route } from "react-router-dom";


import NotFound from '../not-found/not-found'
import ThirdParty from "./third-party";
import TierMain from "./stripe/TierMain/TierMain";
import StripeMain from "./stripe/StripeMain/StripeMain";
import CheckoutMain from "./stripe/CheckoutMain/CheckoutMain";

const ThirdPartyRouter = ({ props, onViewModal, payment, stripe }) => (
  <Routes>
    <Route
      index
      element={<TierMain stripe />}
    />
    
    <Route
      path="pay"
      element={<StripeMain />}
    />
    
    <Route
      path="ty"
      element={<CheckoutMain />}
    />

    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
)

export default ThirdPartyRouter;
