import stream from './ws/stream';

// Cookies
import cookies from 'js-cookie'

// socket.io
import io from 'socket.io-client'

//random text generate method
function randomTextGenerator() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

const guest_id = randomTextGenerator(7)

const getClient = socketURL => {
  const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
  console.log(me)
  let client
  if (typeof EventSource === 'function' && me !== undefined) {
    const id = me.company
    const uid = me.id
    const url = `${id}/company/${uid}`
    // client = new EventSource(`http://127.0.0.1:9000/api/${url}`)
    // client = new EventSource(`https://muvor.xyz/api/${url}`)
    client = new EventSource(`http://192.168.1.254:9000/api/${url}`)
  } else if (typeof EventSource === 'function' && me === undefined) {
    // Guest access
    //TODO: guest features
    // client = new EventSource(`http://127.0.0.1:9000/api/${guest_id}`)
    // client = new EventSource('https://muvor.xyz/api/guest')
    client = new EventSource(`http://192.168.1.254:9000/api/${guest_id}`)
  }
  return client;
}

const getSocket = () => {
  const me = cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
  let client
  if (typeof WebSocket === 'function' && me !== undefined) {
    const id = me.company
    const uid = me.id
    const url = `${uid}/company/${id}`
    // client = io(`ws://127.0.0.1:9000/chat/${url}`)
    client = io(`ws://192.168.1.254:9000`)
    // client = io(`ws://muvor.xyz:9000`);
  } else if (typeof WebSocket === 'function' && me === undefined) {
    // Guest access
    //TODO: guest features
    // client = io(`ws://muvor.xyz:9000`);
    // client = io(`ws://127.0.0.1:9000`)
    client = io(`ws://192.168.1.254:9000`)
  }
  return client;
}

let instance;
export const init = (clientURL) => {
  instance =  getClient(clientURL);
  return instance;
};

let socket
export const sock = () => {
  socket = getSocket();
  return socket;
};

export const getInstance = () => instance;
export const getStream = () => socket;

export default {
  init,
  getInstance,
  getStream,
  sock
};
