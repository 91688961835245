import { FloatBox, Link, Input, Button } from '../../../../common'
import { utils }  from '../../../../lib'
import { formatUSD }  from '../../../../lib/format-numbers'
import stripes from '../redux/stripe-service'

import { getPlan } from '../redux/stripe-actions'

import React, {Component} from 'react';
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken';


import {loadStripe} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements
} from 'react-stripe-elements';

import cookies from 'js-cookie'
// import * as jwt from 'jose'
import axios from 'axios'

// Styles
import styles from './StripeMain.css'

// const stripePromise = loadStripe();

// Set Stripe SessionStorage
sessionStorage.setItem('quantity', '1')

class _SplitFieldsForm extends Component {
  state = {
    errorMessage: '',
    selectedPlan: [],
    card: null,
    exp: null,
    cvc: null
  };

  tier = sessionStorage.getItem('tier') ? JSON.parse(sessionStorage.getItem('tier')) : '';
  additions = sessionStorage.getItem('additions');
  tierItem = sessionStorage.getItem('payment-tier');
  tierTotal = sessionStorage.getItem('total-price');
  bank = sessionStorage.getItem('bank-choice') ? jwt.verify(sessionStorage.getItem('bank-choice'), 'N0!84NK5!') : '';

  componentDidMount() {
    // this.props.getPlan(selectedPlan => this.setState({selectedPlan: selectedPlan}));
  }
  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.props.createSubscription(data)
    const { 
      quantity, 
    } = this.state

    // if (stripe) {
    //   stripe.createToken()
    //   .then(token => { 
    //     console.log(token)
        const data = {
          // amount: selectedPlan.plan.amount_decimal,
          // items: selectedPlan.plan.id,
          // source: token.id,
          // quantity: parseInt(sessionStorage.getItem('quantity')),
          // token
        }
        stripes.charge(data)
         .then(res => {
          const { data } = res
          // var receiptUrl = subscription.items
          // var receiptUrl = 'some url'
          // console.log(data)
          cookies.set('stripe_id', res)
          // document.getElementsByClassName(checkoutForm).styles.display = 'none'
          const regex = /https?:\/\/(?:.*\.)*(.+\..+?)\//
          const root = window.location.pathname.replace(regex, '\\$&')
          window.location = root+'/login/register/registery'
          window.alert(`Payment Successful! \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`)
        }).catch((err) => {window.alert(`${err}: Payment Declined. \n\n No. of users: ${parseInt(sessionStorage.getItem('quantity'))} \n\n Click next to continue`)})
        // })
    // } else {
    //   console.log("Stripe.js hasn't loaded yet.");
    // }
  };
  handleCard = (card) => {
    this.setState({ card });
  };  
  handleExp = (exp) => {
    this.setState({ exp });
  };  
  handleCvc = (cvc) => {
    this.setState({ cvc });
  };
  render() {
    // console.log(this.bank)
    var card = this.bank.cardNumber
    var exp = this.bank.cardExpiry
    var cvc = this.bank.cardCvv
    return (
      // sessionStorage.getItem('stripe_id') ?
        <div className={styles.checkoutForm}>
          <div className={styles.tier}>
            <h7>Price: {formatUSD(Number(this.tierTotal)*100)}</h7>
            <h7>Tier: {this.tier.tier.name}</h7>
            <h7>Total Ads: {this.additions}</h7>
          </div>
          <form onSubmit={this.handleSubmit}>
            <label>
              Card details
              <CardNumberElement onChange={this.handleCard} value={this.state.card || card} placeholder={card} />
            </label>
            <label>
              Expiration date
              <CardExpiryElement onChange={this.handleExp} value={this.state.exp || exp} placeholder={exp} />
            </label>
            <label>
              CVC
              <CardCVCElement onChange={this.handleCvc} value={this.state.cvc || cvc} placeholder={cvc} />
            </label>
            <button type="submit" className={styles.orderButton}>
              Subscribe
            </button>
          </form>
        </div>
    //     :
    //     <div className={styles.checkoutForm}>
    //       <h2>Payment Successful!</h2>
    //       <a>View Receipt</a>
    //       <Link to="/">Home</Link>
    //     </div>
    );
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

class StripeMain extends Component {
  constructor(props){
    super(props)
    this.state = {
      quantity: 1
    }
  }

  componentDidMount() {
    sessionStorage.setItem('quantity', '1')
  }

  onChange = (quantity) => {
    if ( quantity > 0) {
      this.setState({ quantity })
      sessionStorage.setItem('quantity', `${quantity}`)
    }
  }
  render() {
    const { history } = this.props
    let price = localStorage.getItem('total-price');
    let plan = JSON.parse(localStorage.getItem('tier'));
    // const selectedPlan = jwt.verify(plan, 'W4KUU^1337#4(&35')
    const selectedPlan = plan;
    return (
      <div className={styles.container}>
        <FloatBox className={styles.fb}>
          {/* <StripeProvider apiKey="pk_test_51Nc1B2K3O6lDYuYKlVXouO1IZQAEJR3vqmnCztkdmjPSyA9NuZsX6vSkDcp3DQOumSRS8omRYyQwkniQZvvBuax700HkcO1oUx"> */}
            <StripeProvider apiKey="pk_live_51Nc1B2K3O6lDYuYKRFl4UzQQkPHjBzYGewYOE9J0weiiQV31KrCypJmZXy12HVlMZfJXh4UWr8vlbdcxgRull1My0004pUxlqp"> 
            <Elements>
              <SplitFieldsForm />
            </Elements>
          </StripeProvider>
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({ stripeReducer }) => {
  const {
    loading,
    error
  } = stripeReducer

  return {
    loading,
    error
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(StripeMain)