import React, { Component } from "react";
import { Button, Card, CardContent, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import "./make-payment.css"
import { getStripe } from "../../third-party/stripe/redux/stripe-actions";
import { hasAuthTokenAsync } from "../../../lib";
import Cookies from "js-cookie";
import { fundAccount } from "../redux/account-action";
import { connect } from "react-redux";
import { api } from "../../../config";

class InAppPaymentPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTier: "tier1",
      additionalCredits: 0,
      selectedPaymentMethod: "",
      paymentMethods: [
        { id: 1, name: "Credit Card", value: "credit_card" },
        { id: 2, name: "PayPal", value: "paypal" },
        // Add other pre-saved payment methods here
      ],
    };
  }

  componentDidMount() {
    hasAuthTokenAsync()
    .then(() => getStripe())
    .catch(err => err);
  }

  handleTierChange = (tier) => {
    this.setState({ selectedTier: tier });
  };

  handleCreditChange = (event) => {
    const credits = event.target.value;
    this.setState({ additionalCredits: credits });
  };

  handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    this.setState({ selectedPaymentMethod: method });
  };

  handlePurchase = () => {
    const stripe = JSON.parse(sessionStorage.getItem('stripe'))
    const me = JSON.parse(Cookies.get('me'))
    const data = {
      totalAdditions: this.state.additionalCredits,
      tier: stripe[0],
      me
    }
    // Call your payment gateway or API to process the payment
    // this.props.fundAccount(data)
    api.post('/accounts/fund', data)
    .then((data) => {
      // console.log(data);
      window.alert('account funded!');
      window.location.pathname = '/bank';
  })
    .catch(err => err);
  };

  handleCancel = (e) => {
    e.preventDefault();
    window.history.back()
  };

  render() {
    const { additionalCredits, paymentMethods, selectedPaymentMethod } = this.state;
    // Calculate the total price based on the additional credits
    let totalPrice = additionalCredits * 0.1;

    return (
      <div className="payment-portal">
        <Typography variant="h4">In-App Payment Portal</Typography>

        <Card className="additional-credits">
          <CardContent>
            <Typography variant="h6">Additional Credits:</Typography>
            <input
              type="number"
              value={additionalCredits}
              onChange={this.handleCreditChange}
              min={0}
              step={10}
            />
            <Typography variant="body2">
              Each bundle (10) costs $1.00.
            </Typography>
          </CardContent>
        </Card>
        We will charge the default Payment Method
        <Card className="total-price">
          <CardContent>
            <Typography variant="h6">Total Price:</Typography>
            <Typography variant="h5">${totalPrice.toFixed(2)}</Typography>
          </CardContent>
        </Card>
        <div className="buttons-group">
          <Button
            className="purchase-button"
            variant="contained"
            color="error"
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="purchase-button"
            variant="contained"
            color="primary"
            onClick={this.handlePurchase}
          >
            Purchase
          </Button>

          <Link to="/bank/add" className="add-payment-link">
            Add a New Payment Method
          </Link>
        </div>
      </div>
    );
  }
}

const InAppPaymentPortalWrapper = (props) => {
  const { payment } = props;
  const { slug } = useParams();
  
  // Handle the overdue billing payment logic based on the slug value

  return <InAppPaymentPortal {...props} />;
};

export default InAppPaymentPortalWrapper;
