import React, { useCallback, Component } from 'react'
import Dropzone from 'react-dropzone'
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

export default class Upload extends Component {
    render() {
        // const onDrop = useCallback((acceptedFiles) => {
        //     acceptedFiles.forEach((file) => {
        //       const reader = new FileReader()
        
        //       reader.onabort = () => console.log('file reading was aborted')
        //       reader.onerror = () => console.log('file reading has failed')
        //       reader.onload = () => {
        //       // Do whatever you want with the file contents
        //         const binaryStr = reader.result
        //         console.log(binaryStr)
        //       }
        //       reader.readAsArrayBuffer(file)
        //     })
            
        //   }, [])
        // const {getRootProps, getInputProps} = useDropzone({onDrop})
        const fileHandler = (event) => {
            let fileObj = event.target.files[0];    //just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, resp) => {
              if(err){
                console.log(err);            
              }
              else{
                this.setState({
                  cols: resp.cols,
                  rows: resp.rows
                });
              }
            });                 
          }
        return (
            <div>
                <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                    </section>
                )}
                </Dropzone>
                <input type="file" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
                {fileHandler() && <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />}
            </div>
        );
    }
}


