import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import signinReducer from '../views/signin/redux/signin-reducer';
import accountReducer from '../views/bank-account/redux/account-reducer';
import thirdPartyReducer from '../views/third-party/braintree/redux/thirdp-reducer';
import stripeReducer from '../views/third-party/stripe/redux/stripe-reducer';
import jobReducer from '../views/job-view/redux/job-reducer';

const createRootReducer = (history) => 
  combineReducers({
    router: connectRouter(history),
    jobReducer,
    stripeReducer,
    signinReducer, 
    accountReducer, 
    thirdPartyReducer,
});

export default createRootReducer;