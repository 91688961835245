import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom'

// Components
import Profile from './profile/profile';
import Showcase from './showcase/showcase';
import Setting from './setting-page/setting-page';

const SettingsRouter = props => (
  <Routes>
    <Route
      index
      element={<Setting
        comp={props}
        />
      }
    /> 

    <Route
      path="profile"
      element={<Profile
        comp={props}
        />
      }
    />  

    <Route
      path="showcase"
      element={<Showcase
        comp={props}
        />
      }
    /> 
  </Routes>
)


export default SettingsRouter;
