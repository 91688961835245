import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  AsYouType,
  parseNumber,
  formatNumber,
} from '../../../lib/phoneHelper'

import {
  unformatDate,
} from '../../../lib/dateHelper'

import { hasAuthTokenAsync } from '../../../lib'

// Components
import {
  SwitchTable,
  AddressModal,
  AddressLine,
} from '../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
} from '../../../common'

//Actions
import { getJobById } from '../redux/job-action';

import styles from './jobs-view.css'
import { api } from '../../../config';

class JobView extends Component {
  constructor(props) {
    super(props)
    this.job = sessionStorage.getItem('select_job') ? JSON.parse(sessionStorage.getItem('select_job')) : '';

    this.state = {
      ...this.initialState,
    }
  }

  get initialState() {
    return {
      job: null
    }
  }

  componentDidMount() {
    const params = window.location.pathname;
    // console.log(params.split('/')[2])
    hasAuthTokenAsync()
    .then(() => {
      api.get(`/job/details/${params.split('/')[2]}`)
      .then(job => this.setState({ job }))
      .catch(err => err);
    }).catch(console.log)
  }

  closeModal() {
    this.setState({
      noteModal: null,
      addressModal: null,
    })
  }

  renderContactInfo() {
    const job = this.state.job?this.state.job:{_:''}
    return (
      <div className={styles.contactInfo}>
        <div className="name">
          <Span>{job.title}</Span>
        </div>
        <div className="name">
          Email:
          <Span>{job.email}</Span>
        </div>
        <div>
          Phone Number:
          <Span>
            {job.phone || 'No Phone...'}
          </Span>
        </div>
        <div>
          Duration:
          <Span>
            {job.duration || 'No Time Detected...'}
          </Span>
        </div>
        <div>
          Job Terms:
          <Span>
            {job.terms || 'No Terms...'}
          </Span>
        </div>
        <div>
          Budget:
          <Span>
            {job.budget || 0}
          </Span>
        </div>
        <div>
          Category:
          <Span>
            {job.category || 'No Job Category...'}
          </Span>
        </div>
        <div>
          Date Posted: {job.created_at || 'No creation date...'}
        </div>
        <div>
          Address:
          <AddressLine
            state={this.state}
            address={job.location || 'None'}
          />
        </div>
          <Span>
            {job.terms || 'No Terms...'}
          </Span>
        <div className="role">
          <Span>
            {job.description || 'No JD...'}
          </Span>
        </div>
      </div>
    )
  }

  render() {
    if (this.jobs===null) {
      return null
    }

    // console.log(this.state.job)
    
    const {
      title
    } = this.job
    // console.log(this.job)

    return (
      <div>
        <Header className={styles.header}>
          <h2> {title} </h2>
        </Header>
        <Body className={styles.body}>

          {this.renderContactInfo()}

          <div className="switch-buffer" />
        </Body>

        {/* Address Modal */}
        <AddressModal
          // content={addressModal}
          onClickAway={() => this.closeModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobReducer }) => {
  const {
    loading,
    error
  } = jobReducer

  return {
    loading,
    error
  }
}

const actions = {
  getJobById
}

export default connect(mapStateToProps, actions)(JobView);
// export default JobView;
