import React, { Component } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'

// import { hasAuthTokenAsync } from '../../../../lib'

// 3rd Party Components
import FontAwesome from 'react-fontawesome'

// Components
import {
  Tr,
  Icon,
  Header,
  ActionBox,
  Button,
  SearchBar,
  Selector,
  Table,
  SummaryItem,
} from '../../../common'

// Actions
// import {
//   getTeamMembers,
//   filterTeamMembers,
// } from '../../../../actions/main'

// import {
//   setMember,
//   getTeam
// } from '../../../../actions/team'

import styles from './accounts.css'
import PaymentHistory from '../payment-history/payment-history';
import { hasAuthTokenAsync } from '../../../lib';
import { api } from '../../../config';
import Cookies from 'js-cookie';

class AccountsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: true,
      searchType: '',
      search: '',
      bank: [],
      editing: false,
      me: null
    }
  }

  componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')):{ user: ''};
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      this.setState({ me: uData })
      // console.log('hello wlord')
      hasAuthTokenAsync()
      .then(() => {
        api.get(`/accounts/bank/${id}`)
        .then(data => this.setState({ bank: data }))
        .catch(err => err);
      }).catch(err => err);
    })
  }

  setEditState(editing) {
    this.setState({
      ...this.initialState,
      editing,
    })
  }

  get formIsInvalid() {
    const {
      amount,
      type,
    } = this.state

    return !(
      amount
      && type
    )
  }

  save() {
    if (this.formIsInvalid) {
      return
    }

    const {
      type,
      note,
    } = this.state

    const update = {
      amount: Number(this.state.amount),
      type,
      note,
    }

    const { id } = this.props.expense

    this.props.patchExpenseById(id, update)
    this.setState({ editing: false })
  }

  delete() {
    const message = `Are you sure you want to delete this receipt?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }

    const { id } = this.props.expense

    this.props.deleteExpenseById(id)
  }

  renderIcons() {
    const { editing } = this.state
    const { isadmin } = this.props
    if (isadmin) {
      return null
    } else if (editing) {
      return (
        <td className="icons">
          <Icon
            small
            cancel
            onClick={() => this.setEditState(false)}
          />
          <Icon
            small
            save
            onClick={() => this.save()}
          />
        </td>
      )
    } else {
      return (
        <td className="icons">
          <Icon
            small
            edit
            onClick={() => this.setEditState(true)}
          />
          <Icon
            small
            cancel
            onClick={() => this.delete()}
          />
        </td>
      )
    }
  }

  filterUsers(search) {
    const { searchType } = this.state
    // this.props.filterTeamMembers(search, searchType)
    this.setState({ search, searchType })
  }

  viewMember(e, user) {
    e.stopPropagation()
    // this.props.setMember(user)
  }

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th>
            Payment Name
          </th>
          <th>
            Payment Type
          </th>
          <th>
            Institution
          </th>
          <th>
            Account Number
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  
  // renderTableBody() {
  //   const {
  //     products
  //   } = this.props
  //   // .filter(
  //   //   product => {
  //   //     return product.searchType.indexOf(this.state.search) !== -1
  //   //   }
  //   // )
  //   if (!Array.isArray(products)) {
  //     return (
  //       <tbody>
  //         <tr>
  //           <td colSpan="9" className="empty">
  //             No Receipts Found
  //           </td>
  //         </tr>
  //       </tbody>
  //     )
  //   } else {
  //     return (
  //       <tbody>
  //         {products.map(p => (
  //           <JobRow
  //             key={p._id}
  //             product={p}
  //             // checked={checkedAssignments.includes(assignment.id)}
  //           />
  //         ))}
  //       </tbody>
  //     )
  //   }
  // }

  renderTableBody() {
    // console.log(this.state.bank)
    return (
      <tbody>
        { this.state.bank.map(this.renderTableRow.bind(this)) }
      </tbody>
    )
  }

  renderTableRow(bank) {
    const link = `/bank/${bank.id}`
    const date = moment(bank.created_at).format('MM/DD/YYYY')
    return (
      <Tr
        to={link}
        key={bank.id}
      >
        <td className="bold">
          {bank.nickName}
        </td>
        <td>
          {bank.accountType}
        </td>
        <td>
          {bank.bankName}
        </td>
        <td>
          <FontAwesome style={{ marginRight: '10px' }} name="calendar" />
          Ending in: {String(bank.cardNumber || bank.bankAccount).substring(15)}
        </td>
        {this.renderIcons()}
        {/* <td className={styles.detailsCell}>
          <Button
            title="EDIT"
            link={link}
            // onClick={e => this.viewMember(e, teams)}
            // inactive
          />
        </td> */}
      </Tr>
    )
  }

  renderTable() {
    return (
      <Table className={styles.table} hoverable>
        {this.renderTableHead()}
        {this.renderTableBody()}
      </Table>
    )
  }

  get placeholderText() {
    switch (this.state.searchType) {
      case 'name':
        return 'Search By First or Last Name...'
      case 'email':
        return 'Search By Email...'
      default:
        return 'Search By Name or Email...'
    }
  }

  get searchOptions() {
    return [
      {
        value: '',
        display: 'Any',
      },
      {
        value: 'name',
        display: 'Name',
      },
      {
        value: 'email',
        display: 'Email',
      },
    ]
  }


  render() {
    const { searchType, me } = this.state;
    const { tBonus, weeklyHours } = this.props;
    const credits = me?me.paymentToken:'...';
    const lastLogin = me?me.lastLogin:'...';
    const items = [
      {
        title: 'Credits',
        content: credits || '...',
      },
      // {
      //   title: 'Hours Online Weekly',
      //   content: weeklyHours || '...',
      // },
      {
        title: 'Last Logged In',
        content: lastLogin || '...',
      },
    ]
    // console.log(this.state.me)
    // const link = Object.keys(this.props.account.dormant).length === 0 ? '/bank/payment' : '/bank/add-credits'
    const link = this.state.me ? this.state.me.subscription === null ? '/bank/payment' : '/bank/add-credits' : {};
    return (
      <div className={styles.app}>
          <ActionBox className="ActionBox">
            <div className="main">
            <Button
                link={link}
                icon="plus"
                title="ADD CREDITS"
              />
            </div>
            <div className="summary">
            {items.map(({ title, content }) => (
            <SummaryItem
              key={title}
              title={title}
              content={content}
            />
            ))}
            </div>
            <div className="action">
              <Button
                link='/bank/add'
                icon="plus"
                title="ADD ACCOUNT"
              />
            </div>
          </ActionBox>
          {this.renderTable()}
        <PaymentHistory/>
      </div>
    );
  }
}

const mapStateToProps = () => {
  // const {
  //   authDisplay,
  //   plaid,
  //   error,
  // } = accounts

  // const {
  //   isAdmin,
  // } = auth

  // return {
  //   auth: authDisplay,
  //   plaid,
  //   error,
  //   isAdmin,
  // }
}

const actions = { 
  // logout, 
  // getAccounts, 
  // addAccount 
}

// export default connect(mapStateToProps, actions)(AccountsView);
export default AccountsView;
