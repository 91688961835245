// PaymentHistory.js

import React, { Component } from 'react';
import moment from 'moment';
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import './payment-history.css'; 

const paymentHistoryData = [
  // Sample payment history data...
  { id: 1, name: 'John Doe', job: 'Freelance Project', paymentID: 'PAY1234', date: '2023-07-01', amount: 1500 },
  { id: 2, name: 'Jane Smith', job: 'Contract Work', paymentID: 'PAY5678', date: '2023-07-03', amount: 2000 },
  // Add more payment history data here...
];

class PaymentHistory extends Component {
  renderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Job</TableCell>
          <TableCell>Payment ID</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderTableBody() {
    return (
      <TableBody>
        {paymentHistoryData.map((payment) => (
          <TableRow key={payment.id}>
            <TableCell>{payment.name}</TableCell>
            <TableCell>{payment.job}</TableCell>
            <TableCell>{payment.paymentID}</TableCell>
            <TableCell>{moment(payment.date).format('MM/DD/YYYY')}</TableCell>
            <TableCell>${payment.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }

  renderLineChart() {
    const chartData = paymentHistoryData.map((payment) => ({
      date: moment(payment.date).format('MM/DD'),
      amount: payment.amount,
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData}>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Line type="monotone" dataKey="amount" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  render() {
    return (
      <div className="payment-history-container">
        <Typography variant="h3" gutterBottom>
          Payment History Information
        </Typography>

        <Paper elevation={3} className="table-container">
          <Table>
            {this.renderTableHead()}
            {this.renderTableBody()}
          </Table>
        </Paper>

        <Typography variant="h5" gutterBottom>
          Payment History Analysis
        </Typography>
        <div className="chart-container">{this.renderLineChart()}</div>
      </div>
    );
  }
}

export default PaymentHistory;
