import React, { Component } from 'react';
import { createJobListing } from '../redux/job-action';

import './listing-form.css'
import { connect } from 'react-redux';
import { api } from '../../../config';
import Cookies from 'js-cookie';
import { hasAuthTokenAsync } from '../../../lib';

class ListingForm extends Component {
  state = {
    title: '',
    description: '',
    location: '',
    budget: 0,
    category: '',
    duration: '',
    terms: '',
    td: '',
    agreeTerms: false,
    me: null
  };

  componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')):{ user: '' };
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      hasAuthTokenAsync()
      .then(() => {
        api.get(`/accounts/bank/${id}`)
        .then((data) => {
          this.bankData = data;
          // this.stripe = clientSecret
          // loadStripe(clientSecret)
        }).then(() => api.get(`/me/${id}`)
          .then((data) => Cookies.set('me', JSON.stringify(data))))
          .catch(err => console.log(err));
      }).catch(err => err)
    })
  }

  handleSubmit = (event) => {
    // Your code to handle form submission goes here
    event.preventDefault();
    const [me] = JSON.parse(Cookies.get('me'));
    // console.log(me)
    // You can access form data from this.state
    const {
      title,
      description,
      location,
      budget,
      category,
      duration,
      td,
      terms,
      agreeTerms,
    } = this.state;
    const data = {
      phone: me.phone,
      email: me.email,
      title,
      description,
      propertyId: location,
      initialPrice: budget,
      jobType: category,
      duration,
      timeDeviation: td,
      terms,
      termsCheck: agreeTerms,
    }
    this.props.createJobListing(data)
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  render() {
    const {
      title,
      description,
      location,
      budget,
      category,
      duration,
      terms,
      td,
      agreeTerms,
    } = this.state;
    const termsandconditions = ' ';
    return (
      <div className="listing-form-container">
        <h2>Create a Job Listing</h2>
        <form className="listing-form" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={title}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={description}
              onChange={this.handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              name="location"
              value={location}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Budget</label>
            <input
              type="number"
              name="budget"
              value={budget}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Category</label>
            <select
              className="category"
              value={category}
              onChange={this.handleChange}
            >
              <option value="">Select a category</option>
              <option value="design">Design</option>
              <option value="development">Development</option>
              {/* Add more options based on your categories */}
            </select>
          </div>
          <div className="form-group">
            <label>Duration</label>
            <div className="time-form">
              <input
                type="text"
                name="duration"
                value={duration}
                onChange={this.handleChange}
                required
              />
              <select
                className="time-duration"
                value={td}
                onChange={this.handleChange}
                required
              >
                <option value="hour">Hours</option>
                <option value="day">Days</option>
                <option value="week">Weeks</option>
                <option value="month">Months</option>
                <option value="year">Years</option>
                {/* Add more options based on your categories */}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label>Terms</label>
            <textarea
              name="terms"
              value={terms}
              onChange={this.handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="agreeTerms"
                checked={agreeTerms}
                onChange={this.handleChange}
                required
              />
              I agree to our{termsandconditions}
              <a href="https://example.com/faq.html" rel="noreferrer">
                Terms & Conditions
              </a>
            </label>
          </div>
          <button type="submit">Create Listing</button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ jobReducer }) => {
  const {
    error,
    loading,
  } = jobReducer
  return {
    error,
    loading,
  }
}

const actions = {
  createJobListing
}

export default connect(mapStateToProps, actions)(ListingForm);
