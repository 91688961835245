import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import './reset-password.css'

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorMessage: '',
      successMessage: '',
    };
  }

  handleInputChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Implement your reset password logic here
    // Example: send reset password email to the provided email address

    // Reset the form and show success message
    this.setState({ email: '', errorMessage: '', successMessage: 'Password reset email sent successfully.' });
  };

  render() {
    const { email, errorMessage, successMessage } = this.state;

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Reset Password</Typography>
          </Toolbar>
        </AppBar>
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <Typography variant="h5">Forgot Your Password?</Typography>
            <Typography variant="body1">Enter your email address below to reset your password.</Typography>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              className="emailInput"
              value={email}
              onChange={this.handleInputChange}
            />
            {errorMessage && <Typography color="error" className="errorMessage">{errorMessage}</Typography>}
            {successMessage && <Typography color="success" className="successMessage">{successMessage}</Typography>}
            <Button type="submit" variant="contained" color="primary" className="submitButton">
              Reset Password
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
