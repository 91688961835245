import React from 'react';
import {
  Route,
  Routes
} from 'react-router-dom'

// Components
import ListingForm from './listing-form/listing-form';
import NotFound from '../not-found/not-found'
import JobTable from './job-table/job-table';
import JobView from './job-view/job-view';
import JobsView from './jobs-view/jobs-view';

const JobViewRouter = comp => (
  <Routes>    
    <Route
        index
        element={<JobTable comp={comp} />
      }
    /> 

    <Route
      path="createjob"
      element={<ListingForm comp={comp} />}
    /> 


    <Route
      path="/:id"
      element={<JobView comp={comp} />}
    /> 

    <Route
      component={NotFound}
    />
  </Routes>
)


export default JobViewRouter
