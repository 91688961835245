import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  AsYouType,
  parseNumber,
  formatNumber,
} from '../../../lib/phoneHelper'

import {
  unformatDate,
} from '../../../lib/dateHelper'

import { hasAuthTokenAsync } from '../../../lib'

// Components
import {
  SwitchTable,
  AddressModal,
  AddressLine,
} from '../../../shared'

import {
  Input,
  Icon,
  Header,
  Body,
  Span,
} from '../../../common'

//Actions
import { getJobById } from '../redux/job-action';

import styles from './job-view.css'
import { api } from '../../../config';

class JobView extends Component {
  constructor(props) {
    super(props)
    this.job = sessionStorage.getItem('select_job') ? JSON.parse(sessionStorage.getItem('select_job')) : '';

    this.state = {
      ...this.initialState,
    }
  }

  get initialState() {
    const contactInfoState = {
      title: '',
      email: '',
      terms: '',
      duration: '',
      budget: '',
      category: '',
      description: '',
      phone: '',
      created_at: '',
    }

    const addressState = {
      address: null,
      addressModal: null,
    }


    return {
      job: null,
      editing: false,
      ...contactInfoState,
      ...addressState,
    }
  }

  componentDidMount() {
    const params = window.location.pathname;
    // console.log(params.split('/')[2])
    hasAuthTokenAsync()
    .then(() => {
      api.get(`/job/details/${params.split('/')[2]}`)
      .then(job => this.setState({ job }))
      .catch(err => err);
    }).catch(console.log)
  }

  setEditState(editing) {
    this.setState({ ...this.initialState, editing })
  }

  closeModal() {
    this.setState({
      noteModal: null,
      addressModal: null,
    })
  }

  handlePhoneChange(val) {
    if (val.length > 16) {
      return
    }

    const phone = new AsYouType('US').input(val)

    this.setState({ phone })
  }

  insuranceHasFields(data) {
    return (
      data.plan
      || data.bin
      || data.planId
      || data.pcn
      || data.type
    )
  }

  save() {
    const {
      title,
      description,
      location,
      budget,
      category,
      duration,
      terms,
      td,
      agreeTerms,
    } = this.state.job

    const { phone } = parseNumber(this.state.job.phone, 'US')

    const update = {
      title,
      description,
      location,
      budget,
      category,
      duration,
      terms,
      phone
    }

    this.props.patchEmployeeById(this.job.id, update)

    this.setEditState(false)
  }

  delete() {
    const message = `Are you sure you want to delete this supervisor?`
    const confirmed = window.confirm(message)
    if (!confirmed) {
      return
    }
    const id = this.employee._id
    this.props.deleteEmployeeById(id)

    // console.log(this.employee._id)
  }

  renderContactInfo() {
    const {
      editing
    } = this.state

    const {
      title,
      description,
      location, // per address modal
      budget,
      category,
      duration,
      terms,
      phone,
      email,
      td,
      agreeTerms,
    } = this.state
    const job = this.state.job?this.state.job:{_:''}
    return (
      <div className={styles.contactInfo}>
        <div className="name">
          <Span
            editing={editing}
            type="text"
            value={this.state.title}
            placeholder={title}
            onChange={title => this.setState({ title })}
          >{job.title}</Span>
        </div>
        <div className="name">
          Email:
          <Span
            editing={editing}
            type="text"
            value={this.state.email}
            placeholder={email}
            onChange={email => this.setState({ email })}
            >{job.email}</Span>
        </div>
        <div>
          Phone Number:
          <Span
            editing={editing}
            value={phone}
            placeholder="xxx xxx xxxx"
            onChange={this.handlePhoneChange.bind(this)}
          >
            {job.phone || 'No Phone...'}
          </Span>
        </div>
        <div>
          Duration:
          <Span
            editing={editing}
            value={duration}
            placeholder={duration}
            onChange={duration => this.setState({ duration })}
          >
            {job.duration || 'No Time Detected...'}
          </Span>
        </div>
        <div>
          Job Terms:
          <Span
            editing={editing}
            value={terms}
            placeholder={terms}
            onChange={terms => this.setState({ terms })}
          >
            {job.terms || 'No Terms...'}
          </Span>
        </div>
        <div>
          Budget:
          <Span
            editing={editing}
            value={budget}
            placeholder={budget}
            onChange={budget => this.setState({ budget })}
          >
            {job.budget || 0}
          </Span>
        </div>
        <div>
          Category:
          <Span
            editing={editing}
            value={category}
            placeholder={category}
            onChange={category => this.setState({ category })}
          >
            {job.category || 'No Job Category...'}
          </Span>
        </div>
        <div>
          Date Posted: {job.created_at || 'No creation date...'}
        </div>
        <div>
          Address:
          <AddressLine
            editing={editing}
            state={this.state}
            address={location || 'None'}
            onChange={newState => this.setState(newState)}
          />
        </div>
          <Span
            label="Job Terms"
            editing={editing}
            value={terms}
            placeholder={terms}
            onChange={terms => this.setState({ terms })}
          >
            {job.terms || 'No Terms...'}
          </Span>
        <div className="role">
          <Span
            label="Job Description"
            type="text"
            editing={editing}
            value={description}
            onChange={description => this.setState({ description })}
          >
            {job.description || 'No JD...'}
          </Span>
        </div>
      </div>
    )
  }

  render() {
    if (this.jobs===null) {
      return null
    }

    // console.log(this.state.job)

    const {
      editing,
      addressModal,
    } = this.state

    const {
      title
    } = this.job

    return (
      <div>
        <Header className={styles.header}>
          <h2>
            {title}
            {!editing ? (
              <div>
                <Icon
                  edit
                  onClick={() => this.setEditState(true)}
                />
                <Icon
                  cancel
                  onClick={() => this.delete()}
                />
              </div>
            ) : (
              <div>
                <Icon
                  cancel
                  onClick={() => this.setEditState(false)}
                />
                <Icon
                  save
                  onClick={() => this.save()}
                />
              </div>
            )}
          </h2>
        </Header>
        <Body className={styles.body}>

          {this.renderContactInfo()}

          <div className="switch-buffer" />
        </Body>

        {/* Address Modal */}
        <AddressModal
          content={addressModal}
          onSubmit={address => this.setState({ address })}
          onClickAway={() => this.closeModal()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ jobReducer }) => {
  const {
    loading,
    error
  } = jobReducer

  return {
    loading,
    error
  }
}

const actions = {
  getJobById
}

export default connect(mapStateToProps, actions)(JobView);
// export default JobView;
