// Router
import { push } from 'react-router-redux'

// Types
import * as accountsTypes from './account-types'

// Services
import accounts from './account-service'

import cookies from 'js-cookie';

const {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  FUND_ACCOUNT,
  FUND_ACCOUNT_SUCCESS,
  FUND_ACCOUNT_FAILURE,
  FILTER_ACCOUNTS,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_SUCCESS,
  SET_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  PATCH_ACCOUNT,
  PATCH_ACCOUNT_FAILURE,
  PATCH_ACCOUNT_SUCCESS,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILURE,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = accountsTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { _id, id, company, mvrxt, account } = me
const uid=id||_id,cid=company;

// Accounts
export const getAccounts = () => dispatch => {
  dispatch({
    type: GET_ACCOUNTS,
  })

  accounts.getAll()
    .then(res => onGetAccountsSuccess({dispatch, res}))
    .catch(err => onGetAccountsFailure({dispatch, err}))
}

function onGetAccountsSuccess({dispatch, res}) {
  console.log('getAccounts success');
  dispatch({
    type: GET_ACCOUNTS_SUCCESS,
    payload: res.data,
  })
}

function onGetAccountsFailure({dispatch, err}) {
  console.log('getAccounts failure', err);
  dispatch({
    type: GET_ACCOUNTS_FAILURE,
    payload: err.message || 'Get Accounts Failed',
  })
}

export const fundAccount = payload => dispatch => {
  dispatch({
    type: FUND_ACCOUNT,
  })
  me && accounts.fundAccount(payload)
    .then(res => onFundAccountSuccess({dispatch, res}))
    .catch(err => onFundAccountFailure({dispatch, err}))
}

function onFundAccountSuccess({dispatch, res}) {
  console.log('FundAccount success');
  dispatch({
    type: FUND_ACCOUNT_SUCCESS,
  })
  window.location.pathname = '/bank'
}

function onFundAccountFailure({dispatch, err}) {
  console.log('FundAccount failure', err);
  const payload = err.message || 'FundAccount Failed'
  window.alert(payload)
  dispatch({
    type: FUND_ACCOUNT_FAILURE,
    payload,
  })
}

export const filterAccounts = input => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { accounts } = getState().accounts
  const accountsDisplay = !search ? accounts : accounts.filter(account => {
    const name = account.name.toLowerCase()
    return name.includes(search)
  })

  dispatch({
    type: FILTER_ACCOUNTS,
    payload: accountsDisplay,
  })
}

export const filterAccountsByRep = input => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { accounts } = getState().accounts
  const accountsDisplay = !search ? accounts : accounts.filter(account => {
    const rep = account.rep
    return rep.includes(search)
  })

  dispatch({
    type: FILTER_ACCOUNTS,
    payload: accountsDisplay,
  })
}

export const createAccount = payload => dispatch => {
  dispatch({
    type: CREATE_ACCOUNT,
  })
  me && accounts.patchById(payload)
    .then(res => onCreateAccountSuccess({dispatch, res}))
    .catch(err => onCreateAccountFailure({dispatch, err}))
}

function onCreateAccountSuccess({dispatch, res}) {
  console.log('createAccount success');
  dispatch({
    type: CREATE_ACCOUNT_SUCCESS,
  })
  window.location.reload()
}

function onCreateAccountFailure({dispatch, err}) {
  console.log('createAccount failure', err);
  const payload = err.message || 'CreateAccount Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_ACCOUNT_FAILURE,
    payload,
  })
}

export const setAccount = payload => {
  sessionStorage.setItem('select_account', JSON.stringify(payload))
  return {
    type: SET_ACCOUNT,
    payload,
  }
}

export const getAccountById = () => (dispatch, getState) => {
  // check if a account has been set yet
  const { account } = getState().accounts
  if (account && account.id !== company) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }
  dispatch({
    type: GET_ACCOUNT,
  })
  accounts.getById(company || cid)
    .then(res => onGetAccountSuccess({dispatch, res}))
    .catch(err => onGetAccountFailure({dispatch, err}))
}

function onGetAccountSuccess({dispatch, res}) {
  console.log('getAccount success');
  dispatch({
    type: GET_ACCOUNT_SUCCESS,
    payload: res.data,
  })
}

function onGetAccountFailure({dispatch, err}) {
  console.log('getAccount failure', err);
  const payload = err.message || 'GetAccount Failed'
  window.alert(payload)
  dispatch({
    type: GET_ACCOUNT_FAILURE,
    payload,
  })
  window.location.reload()
}

export const patchAccountById = update => dispatch => {
  dispatch({
    type: PATCH_ACCOUNT,
  })
  accounts.patchAccount(update)
    .then(res => onPatchAccountSuccess({dispatch, res}))
    .catch(err => onPatchAccountFailure({dispatch, err}))
}

function onPatchAccountSuccess({dispatch, res}) {
  console.log('getAccount success');
  dispatch({
    type: PATCH_ACCOUNT_SUCCESS,
    payload: res,
  })
}

function onPatchAccountFailure({dispatch, err}) {
  console.log('patchAccount failure', err);
  const payload = err.message || 'PatchAccount Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_ACCOUNT_FAILURE,
    payload,
  })
}

// Accounts
export const getTransactions = () => dispatch => {
  dispatch({
    type: GET_TRANSACTIONS,
  })

  accounts.getTransactions(cid,uid)
    .then(res => onGetTransactionsSuccess({dispatch, res}))
    .catch(err => onGetTransactionsFailure({dispatch, err}))
}

function onGetTransactionsSuccess({dispatch, res}) {
  console.log('getTransactions success');
  dispatch({
    type: GET_TRANSACTIONS_SUCCESS,
    payload: res.data,
  })
}

function onGetTransactionsFailure({dispatch, err}) {
  console.log('getTransactions failure', err);
  dispatch({
    type: GET_TRANSACTIONS_FAILURE,
    payload: err.message || 'getTransactions Failed',
  })
}

export const createSubscription = data => (dispatch, getState) => {
  dispatch({
    type: CREATE_TRANSACTION,
  })
  accounts.postSubscription(data)
    .then(res => onCreateTransactionSuccess({dispatch, getState, res}))
    .catch(err => onCreateTransactionFailure({dispatch, err}))
}

function onCreateTransactionSuccess({dispatch, getState, res}) {
  console.log('createTransaction success');
  const { transaction } = res
  // update the assignments array on the account
  window.location.pathname = '/bank'
  dispatch({
    type: CREATE_TRANSACTION_SUCCESS,
    payload: account,
  })
}

function onCreateTransactionFailure({dispatch, err}) {
  console.log('createTransaction failure', err);
  dispatch({
    type: CREATE_TRANSACTION_FAILURE,
    payload: err.message || 'CreateTransaction Failed',
  })
}

export const deleteAccountById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_ACCOUNT,
  })
  accounts.deleteById(id)
    .then(res => onDeleteAccountSuccess({dispatch, getState, res}))
    .catch(err => onDeleteAccountFailure({dispatch, err}))
}

function onDeleteAccountSuccess({dispatch, getState, res}) {
  console.log('deleteAssignment success');

  dispatch({
    type: DELETE_ACCOUNT_SUCCESS,
    payload: [res],
  })
}

function onDeleteAccountFailure({dispatch, err}) {
  console.log('deleteAccount failure', err)
  const payload = err.message || 'deleteAccount Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_ACCOUNT_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
