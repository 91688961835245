import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

class ReviewApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Existing state properties
      myInterval: 5000,
      noWrapSlides: false,
      active: 0,
      pager: {},
      baseUrl: window.location.host,
      pageSize: 10,
      zipcode: localStorage.getItem('currentUser') ? localStorage.getItem('currentUser').zipcode : '',
      token: localStorage.getItem('currentUser') ? localStorage.getItem('currentUser').token : '',
      data: {
        zipcode: localStorage.getItem('currentUser') ? localStorage.getItem('currentUser').zipcode : '',
        page: Number(1),
        radius: 0,
      },
      result: [
        // Sample data for demonstration (replace with your actual job data)
        {
          id: 1,
          applicantName: 'John Doe',
          applicantAvatar: 'https://example.com/avatar1.jpg',
          applicantData: {
            name: 'John Doe',
            title: 'Web Developer',
            avatar: 'https://example.com/avatar1.jpg',
            bio: 'I am a web developer with experience in React and Node.js.',
            skills: ['HTML', 'CSS', 'JavaScript', 'React', 'Node.js'],
            portfolioLink: 'https://example.com/portfolio1',
          },
        },
        {
          id: 2,
          applicantName: 'Jane Smith',
          applicantAvatar: 'https://example.com/avatar2.jpg',
          applicantData: {
            name: 'Jane Smith',
            title: 'Graphic Designer',
            avatar: 'https://example.com/avatar2.jpg',
            bio: 'I am a graphic designer with expertise in Adobe Illustrator and Photoshop.',
            skills: ['Adobe Illustrator', 'Adobe Photoshop', 'Logo Design', 'Typography'],
            portfolioLink: 'https://example.com/portfolio2',
          },
        },
      ],
      isApplicantModalOpen: false, // State to control the applicant data modal
      selectedApplicant: null, // State to store the selected applicant's data
    };
  }

  // Existing methods and functions

  handleViewApplicantData = (applicant) => {
    this.setState({
      selectedApplicant: applicant,
      isApplicantModalOpen: true,
    });
  };

  handleCloseApplicantModal = () => {
    this.setState({
      isApplicantModalOpen: false,
    });
  };

  render() {
    const { result, isApplicantModalOpen, selectedApplicant } = this.state;

    return (
      <div>
        <Grid container spacing={2}>
          {result.map((job) => (
            <Grid item xs={12} sm={6} md={4} key={job.id}>
              <Paper className="job-card">
                {/* Existing code for job card */}
                <div className="applicant-info">
                  <Typography variant="subtitle1">{job.applicantName}</Typography>
                  <Avatar alt={job.applicantName} src={job.applicantAvatar} />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handleViewApplicantData(job.applicantData)}
                  >
                    View Applicant Data
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Modal to view applicant data */}
        <Modal open={isApplicantModalOpen} onClose={this.handleCloseApplicantModal}>
          <Paper sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 4 }}>
            {selectedApplicant && (
              <Box>
                <Typography variant="h5">{selectedApplicant.name}</Typography>
                <Typography variant="subtitle1">{selectedApplicant.title}</Typography>
                <Avatar alt={selectedApplicant.name} src={selectedApplicant.avatar} sx={{ width: 120, height: 120 }} />
                <Typography variant="body1">{selectedApplicant.bio}</Typography>
                <div className="skills">
                  {selectedApplicant.skills.map((skill, index) => (
                    <span key={index} className="skill">
                      {skill}
                    </span>
                  ))}
                </div>
                <div className="portfolioLink">
                  <Button variant="outlined" color="primary" href={selectedApplicant.portfolioLink} target="_blank">
                    Visit Portfolio
                  </Button>
                </div>
              </Box>
            )}
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default ReviewApply;
