/* eslint-disable no-undef */
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Button, FormModal, Span } from '../../../common';

import { braintreePayment } from './redux/thirdp-action'

import './braintree.css'
class BrainTree extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onSubmit(e) {
        e.preventDefault()
        console.log(e)
        this.props.braintreePayment(e)
        &&this.props.onClickAway()
    }

    render() { 
        const privacyString = '(We do not store card data)'
        const tier = localStorage.getItem('payment-tier');
        const price = Number(localStorage.getItem('total-price'));
        var sKey = 'sandbox_d5wg6rpm_gsmpdp4dywnm7wvr';
        braintree.dropin.create({
            authorization: sKey,
            container: '#dropin-container',
            paypal: {
              flow: 'checkout',
              buttonStyle: {
                color: 'blue',
                shape: 'rect',
                size: 'medium'
              },
              amount: price,
              currency: 'USD'
            }
          }, (createErr, instance) => {
              instance !== undefined && instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                // When the user clicks on the 'Submit payment' button this code will send the
                // encrypted payment information in a variable called a payment method nonce
                // $.ajax({
                //   type: 'POST',
                //   url: '/checkout',
                //   data: { 'paymentMethodNonce': payload.nonce }
                // }).done(function (result) {
                //   // Tear down the Drop-in UI
                //   instance.teardown(function (teardownErr) {
                //     if (teardownErr) {
                //       console.error('Could not tear down Drop-in UI!');
                //     } else {
                //       console.info('Drop-in UI has been torn down!');
                //       // Remove the 'Submit payment' button
                //       $('#submit-button').remove();
                //     }
                //   });
          
                //   if (result.success) {
                //     $('#checkout-message').html('<h1>Success</h1><p>Your Drop-in UI is working! Check your <a href="https://sandbox.braintreegateway.com/login">sandbox Control Panel</a> for your test transactions.</p><p>Refresh to try another transaction.</p>');
                //   } else {
                //     console.log(result);
                //     $('#checkout-message').html('<h1>Error</h1><p>Check your console.</p>');
                //   }
                // });
            });
        });

        return (
            <FormModal
                title="Checkout"
                onClickAway={this.props.onClickAway}
                visible={!!this.props.content}
                onSubmit={this.onSubmit.bind(this)}>
                <div className="new_tr_container">
                    <h6>{privacyString}</h6>
                    <div id="dropin-wrapper">
                        <div id="checkout-message"></div>
                        <div id="dropin-container" className="wsize"></div>
                    </div>
                    <Span className="price-footer">
                        <div className="price-tier">
                            {tier}    
                        </div>
                        <div className="price-tier">
                            Total: ${price.toFixed(2)}
                        </div>
                    </Span>
                    <Button className="price-submit-button" title="Submit" wide alt /> 
                </div>
            </FormModal>
        );
    }
}

const mapStateToProps = ({ thirdPartyReducer }) => {
    const {
        braintreePayment
    } = thirdPartyReducer
    return {
        braintreePayment
    }
}

const actions = {
    braintreePayment
}
  
export default connect(mapStateToProps, actions)(BrainTree)
