/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../../config'

export default {
  getAll() {
    const url = '/thirdp/stripe/plans'
    return api.get(url)
  },

  post(data) {
    const url = '/thirdp/stripe'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/thirdp/stripe/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/thirdp/stripe/${id}`
    return api.patch(url, update)
  },
  
  patchStripe(data) {
    const url = '/thirdp/stripe/update'
    return api.post(url, data)
  },

  charge(data) {
    const url = '/thirdp/stripe/charge'
    return api.post(url, data)
  },
}
