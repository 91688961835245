/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../config'

export default {
  post(data) {
    const url = '/company'
    return api.post(url, data)
  },

  getById(id) {
    const url = `/company/${id}`
    return api.get(url)
  },

  patchById(id, update) {
    const url = `/company/${id}`
    return api.patch(url, update)
  },
  login(data) {
    const url = '/user/login'
    return api.post(url, data)
  },
  sendSMS(data) {
    const url = '/user/sms'
    return api.post(url, data)
  },

  resetPassword(data) {
    const url = '/user/passwordreset'
    return api.post(url, data)
  },

  createPassword(data) {
    const url = `/user/reset`
    return api.post(url, data)
  },

  // claimAccount(data) {
  //   const url = '/auth/claim-account'
  //   return api.post(url, data)
  // },
  register(data) {
    const url = '/user/signup'
    return api.post(url, data)
  }
}
