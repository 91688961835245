import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import menuLines from '../../resources/images/menu-three-horizontal-lines-symbol.svg';

import './job-find.css'

class FindJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: '',
      miles: '',
      zipcode: '',
      excludeJobs: false,
    };
  }

  handleSearch = () => {
    // Implement search logic here
  };

  render() {
    const { sortBy, miles, zipcode, excludeJobs } = this.state;

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Find More Jobs
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Sort by</InputLabel>
              <Select value={sortBy} onChange={(e) => this.setState({ sortBy: e.target.value })}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="-createdAt">Newest</MenuItem>
                <MenuItem value="initial_price">Price</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel>Within</InputLabel>
              <Select value={miles} onChange={(e) => this.setState({ miles: e.target.value })}>
                <MenuItem value="">All</MenuItem>
                <MenuItem value="5">5 MILES</MenuItem>
                <MenuItem value="10">10 MILES</MenuItem>
                {/* Add other mile options here */}
              </Select>
            </FormControl>
            <TextField
              label="Of"
              type="text"
              value={zipcode}
              onChange={(e) => this.setState({ zipcode: e.target.value })}
              sx={{ m: 1, minWidth: 120 }}
            />
            <Button variant="contained" onClick={this.handleSearch}>
              Set
            </Button>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {/* Display job cards here */}
          </Grid>
          <div sx={{ textAlign: 'center', mt: 2 }}>
            <Button variant="contained">
              Load More
            </Button>
          </div>
        </Container>
      </div>
    );
  }
}

export default FindJob;
