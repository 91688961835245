import React, { Component } from 'react';

// Cookies
import cookies from 'js-cookie'

import Fryklogo from '../../resources/images/Fryklance-woname.png'

import './header.css'
import { logout } from '../signin/redux/signin-action';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMenuOpen: false
     }
  }

  guest = () => {
    const {
      isMenuOpen
    } = this.state;
    const toggleMenu = () => {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };
    return (
      <header className="main-header">
        <div className="logo">
          <a href="/"><img src={Fryklogo} width={35} height={45} alt=""/></a>
        </div>
        <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <a href="/">Home</a>
          <a href="#job-posts">Find a Job</a>
          <a href="#about-us">About Us</a>
          <a href="#our-clients">Our Clients</a>
          <a href="#contact-us">Contact Us</a>
          <a href="/signin">Sign On</a>
        </nav>
        <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        {isMenuOpen && <nav className="hamburger active">
          <a href="/"><img src={Fryklogo} width={50} height={60} alt=""/></a>
          <a href="#job-posts" onClick={toggleMenu}>Find a Job</a>
          <a href="#about-us" onClick={toggleMenu}>About Us</a>
          <a href="#our-clients"onClick={toggleMenu} >Our Clients</a>
          <a href="#contact-us" onClick={toggleMenu}>Contact Us</a>
          <a href="/signin" onClick={toggleMenu}>SignOn</a>
        </nav>}
      </header>
    )
  }
  home = () => {
    const {
      isMenuOpen
    } = this.state;
    const toggleMenu = () => {
      this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };
    return (
      <header className="main-header">
        <div className="logo">
          <a href="/"><img src={Fryklogo} width={35} height={45} alt=""/></a>
        </div>
        <nav className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
          <a href="/viewjobs">View Jobs</a>
          <a href="/bank">Fund Account</a>
          <a href="/settings">Settings</a>
          <a href="/signin" onClick={logout}>Logout</a>
        </nav>
        <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        {isMenuOpen && <nav className="hamburger active">
          <a href="/viewjobs"><img src={Fryklogo} width={50} height={60} alt=""/></a>
          <a href="/viewjobs" onClick={toggleMenu}>Post Job</a>
          <a href="/bank" onClick={toggleMenu}>Fund Account</a>
          <a href="/settings" onClick={toggleMenu}>Settings</a>
          <a href="/signin" onClick={logout && toggleMenu}>Logout</a>
        </nav>}
      </header>
    )
  }
  render() { 
    // const {
    //   me
    // } = this.props;
    // let me = 'hi';
    let me = cookies.get('me') ? JSON.parse(cookies.get('me')) : '';
    return (
      <div>
        { me ? this.home() : this.guest() }
      </div>
        
    );
  }
}
 
export default Header;