import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Container, Typography, TextField, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.css';
import FindJob from './views/job-find/job-find';
import Settings from './views/settings/settings';
import NotFound from './views/not-found/not-found';
import BankPage from './views/bank-account/bank-account';
import { useState } from 'react';
import ForgotPassword from './views/forgot-password/forgot-password';
import ResetPassword from './views/reset-password/reset-password';
import PublicProfile from './views/public-profile/public-profile';
import ReviewApply from './views/review-apply/review-apply';
import Scheduled from './views/scheduled/scheduled';
import Communications from './views/communications/communications';
import Application from './views/apply-form/apply-form';
import SignIn from './views/signin/signin.router';
import Fryklogo from './resources/images/Fryklance-woname.png'
import WakuuLogo from './resources/images/wakuulogo.png'
import FoodLogo from './resources/images/foodlogo.png'
import MuvorLogo from './resources/images/muvorlogo.png'
import AppleStore from './resources/images/apple-store.png'
import PlayStore from './resources/images/play-store.png'

import { i18n } from './shared';


// Route Abstractions
import { routerMiddleware } from 'react-router-redux'
import { Provider as ReduxProvider } from 'react-redux';
import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import localStorageService from './shared/localstorage';
import { createRootReducer, getDateObject, hasAuthTokenAsync } from './lib';
import history from './lib/history';

import { ConnectedRouter } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import createLogger from 'redux-logger';
import Header from './views/header/header';
import BrainTree from './views/third-party/braintree/braintree';
import ThirdParty from './views/third-party/third-party';
import JobView from './views/job-view/job-view';
import Cookies from 'js-cookie';
import { api } from './config';
import { Selector, Span, Table, Button } from './common';
import JobsView from './views/job-view/jobs-view/jobs-view';


function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState('');
  const [simpleJobChecked, setSimpleJobChecked] = useState(false);
  const [complexJobChecked, setComplexJobChecked] = useState(false);
  const [jobs, setJobs] = useState([])
  useEffect(() => {
    api.get('/job/all')
    .then(data => setJobs({ jobs: data }))
    .catch(err => err);
  })

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleJobTypeChange = (event) => {
    setSelectedJobType(event.target.value);
  };

  const handleSimpleJobChange = (event) => {
    setSimpleJobChecked(event.target.checked);
  };

  const handleComplexJobChange = (event) => {
    setComplexJobChecked(event.target.checked);
  };
    // login logic
    // if(AuthenticationService.steps() == 1){
    //   $location.path("/profile");
    // }
    // else if(AuthenticationService.steps() == 2){
    //     $location.path("/bank-account");
    // }
    // else if (AuthenticationService.steps() == 3) {
    //     $location.path("/public-profile");
    // }
    // else if (AuthenticationService.steps() == 4) {            
    //     if(next.hideAfterlogin && AuthenticationService.isLogedIn()){
    //         $location.path('/my-profile');
    //     }
    // }


    // if (!AuthenticationService.isLogedIn() && next.isLogin) {
    //     if(next.hideAfterlogin){
    //         $rootScope.savedLocation = '/my-profile';
    //     }else{
    //         $rootScope.savedLocation = $location.url();    
    //     }            
    //     $location.path('/signin');
    // }
    // 3rd party sign on
    // switch(location.host) {
    //     default:
    //         $window.fbAsyncInit = function() {
    //             FB.init({
    //               appId      : '1845635442357634',
    //               cookie     : true,  // enable cookies to allow the server to access
    //               xfbml      : true,
    //               version    : 'v2.9'
    //             });
    //         };

    //           (function(d, s, id){
    //              var js, fjs = d.getElementsByTagName(s)[0];
    //              if (d.getElementById(id)) {return;}
    //              js = d.createElement(s); js.id = id;
    //              js.src = "//connect.facebook.net/en_US/sdk.js";
    //              fjs.parentNode.insertBefore(js, fjs);
    //            }(document, 'script', 'facebook-jssdk'));            
    //     break;
    //     case 'localhost':
    //         $window.fbAsyncInit = function() {
    //             FB.init({
    //                 appId      : '1403156389727498',
    //                 cookie     : true,  // enable cookies to allow the server to access 
    //                 xfbml      : true,  // parse social plugins on this page
    //                 version    : 'v2.9' // use graph api version 2.8          
    //             });
    //         };

    //         (function(d, s, id) {
    //             var js, fjs = d.getElementsByTagName(s)[0];
    //             if (d.getElementById(id)) return;
    //             js = d.createElement(s); js.id = id;
    //             js.src = "//connect.facebook.net/en_US/sdk.js";
    //             fjs.parentNode.insertBefore(js, fjs);
    //         }(document, 'script', 'facebook-jssdk'));        
    //     break;
    // }   

    const displayDateTimeFormate = (input) => {
      var output;

      var inputDate = new Date(input);
      var todaysDate = new Date();

      if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
          var inputDate = new Date(input);
          var dd = "AM";
          var hh = inputDate.getHours();
          var m = inputDate.getMinutes();
          var h = hh;
          
          if (h >= 12) {
              h = hh-12;
              dd = "PM";
          }
          if (h == 0) {
              h = 12;
          }

          h = h<10?"0"+h:h;
          output = h+":"+m+" "+dd;
      }else{
          var months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];            
          output = months[inputDate.getMonth()]+" "+inputDate.getDay();
      }

      return output;
  }

  const renderTableHead = () => {
    return (
      <thead className="head">
        <tr>
          <th>
            Title
          </th>
          <th>
            Type of Work
          </th>
          <th>
            Email
          </th>
          <th>
            Phone Number
          </th>
          <th>
            Price
          </th>
          <th>
            Duration
          </th>
          <th>
            Description
          </th>
          <th />
        </tr>
      </thead>
    )
  }

  const renderTableBody = () => {
    // console.log(jobs.jobs);
    if (!Array.isArray(jobs.jobs)) {
      return (
        <tbody>
          <tr>
            <td colSpan="9" className="empty">
              No Jobs Available
            </td>
          </tr>
        </tbody>
      )
    } else {
      return (
        <tbody>
          {jobs.jobs.map((j) => renderTableRow(j))}
        </tbody>
      )
    }
  }

  const renderTableRow = (jobs) => {    
    return (
      <tr className="map">
        <td>
            {jobs.title}
        </td>

        <td className="email">
          {jobs.jobType}
        </td>

        <td className="email">
          {jobs.email}
        </td>

        <td>
          <Span icon="phone">
            {jobs.phone || 'None'}
          </Span>
        </td>

        <td className="email">
          {jobs.initialPrice}
        </td>

        <td>
          {jobs.duration}
        </td>

        <td>
          {jobs.description}
        </td>
        <td className="detailsCell">
          <Button
            title="JOB DETAILS"
            link={`/view/${jobs.id}`}
            // onClick={(job) => setJob(job)}
          />
        </td>
      </tr>
    )
  }

  const renderTable = () => {
    return (
      <Table className="table-body">
        {renderTableHead()}
        {renderTableBody()}
      </Table>
    )
  }
  
  const ComplexPage = () => {
    return (
      <div className="complex-page">
        <div className="billboard-section">
          <div className="billboard-content">
          <img src={Fryklogo} alt="logo" className="hero-image" />
            <Typography variant="h1" className="title">
              Welcome to Fryklance
            </Typography>
            <Typography variant="subtitle1" className="subtitle">
              Your platform for finding the best freelancers for your projects.
            </Typography>
            <div className="mobile-download-buttons">
              <a href="/" className="mobile-download-icon">
                <img src={AppleStore} alt="" width={100} height={35}/>
              </a>
              <a href="/" className="mobile-download-icon">
                <img src={PlayStore} alt="" width={100} height={35}/>
              </a>
            </div>
          </div>
        </div>
        <section id="job-posts" className="job-posts-section">
          <Container maxWidth="md">
            <Typography variant="h2" className="section-title">
              Latest Job Posts
            </Typography>
            <div className="filter-options">
              <TextField
                label="Search Jobs"
                variant="outlined"
                size="small"
                className="search-field"
                // Add search functionality here
              />
              <Select
                label="Job Type"
                variant="outlined"
                value={selectedJobType}
                onChange={handleJobTypeChange}
                className="job-type-select"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="design">Design</MenuItem>
                <MenuItem value="development">Development</MenuItem>
                <MenuItem value="writing">Writing</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
              <FormControlLabel
                control={<Checkbox color="primary" checked={simpleJobChecked} onChange={handleSimpleJobChange} />}
                label="Simple Jobs"
                className="complexity-checkbox"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={complexJobChecked} onChange={handleComplexJobChange} />}
                label="Complex Jobs"
                className="complexity-checkbox"
              />
            </div>

          </Container>
            {/* For each job post, create a card or a list item */}
            <div className='table-render'>
            {renderTable()}

            </div>
        </section>
        <section id="about-us" className="about-us-section">
          <Container maxWidth="md">
            <h2 className="section-title">
              About Us
            </h2>
            <div className="section-description">
              Welcome to Fryklance, where we connect businesses with top-notch freelancers from around
              the world. Our mission is to provide a seamless platform for finding and hiring the
              perfect talent for your projects.
            </div>
          </Container>
        </section>
  
        <section id="our-services" className="our-services-section">
          <Container maxWidth="md">
            <h2 className="section-title">
              Our Services
            </h2>
            <Typography variant="body1" className="section-description">
              Fryklance offers a range of services to ensure you get the most out of your freelancing
              experience:
            </Typography>
            <ul className="service-list">
              <li>Find Freelancers: Post your projects and find the best talent for the job.</li>
              <li>Manage Projects: Collaborate seamlessly and keep track of your projects.</li>
              <li>Secure Payments: Trust our secure payment system for hassle-free transactions.</li>
            </ul>
          </Container>
        </section>
  
        <section id="our-clients" className="our-clients-section">
          <Container maxWidth="md">
            <Typography variant="h2" className="section-title">
              Our Clients
            </Typography>
            <Typography variant="body1" className="section-description">
              We are proud to have served a diverse range of clients from various industries,
              including:
            </Typography>
            <div className="client-logos">
              {/* Placeholder logos/images go here */}
              <img src={Fryklogo} alt="Client Logo" className="client-logo" />
              <img src={FoodLogo} alt="Client Logo" className="client-logo" />
              <img src={WakuuLogo} alt="Client Logo" className="client-logo" />
              <img src={MuvorLogo} alt="Client Logo" className="client-logo" />
              {/* Add more logos as needed */}
            </div>
          </Container>
        </section>
  
        <section id="testimonials" className="testimonials-section">
          <Container maxWidth="md">
            <Typography variant="h2" className="section-title">
              Testimonials
            </Typography>
            <div className="testimonial">
              <Typography variant="body1">
                "Working with Fryklance was an absolute pleasure. Their platform made it easy to find
                the right freelancer for our project, and the results exceeded our expectations."
              </Typography>
              <Typography variant="subtitle2">- Happy Client</Typography>
            </div>
            <div className="testimonial">
              <Typography variant="body1">
                "Fryklance has been an invaluable resource for our company. Their services helped us
                scale our projects efficiently."
              </Typography>
              <Typography variant="subtitle2">- Satisfied Customer</Typography>
            </div>
            {/* Add more testimonials as needed */}
          </Container>
        </section>
        <section id="contact-us" className="contact-section">
          <Container maxWidth="md">
            <Typography variant="h2" className="section-title">
              Contact Us
            </Typography>
            <form className="contact-form">
              {/* Add your contact form fields here */}
              <div className="form-field">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-field">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-field">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" required />
              </div>
              <Button variant="contained" color="primary" type="submit">
                Send Message
              </Button>
            </form>
          </Container>
        </section>
  
        <footer className="footer">
          <Container maxWidth="md">
            <Typography variant="body1" className="footer-text">
              &copy; {new Date().getFullYear()} Fryklance. All rights reserved.
            </Typography>
            {/* Add additional footer links and content here */}
          </Container>
        </footer>
      </div>
    );
  };

  /*
    handles the data stored reset when the application version changes
    if that is needed
  */
  localStorageService.init('0.1.0');
  const persistedState = localStorageService.loadState();

  // update the usec locale by the i18next from the localstorage
  let changeLanguage = i18n.changeLanguage (persistedState && persistedState.locale);

//----------------------------------------------------------------------------//

  // create browser history to use in middleware
  const historyMiddleware = routerMiddleware(history)
  const store = configureStore(
    { reducer: createRootReducer(history) }, // root reducer with router state
    persistedState,
    compose(
      applyMiddleware(
        historyMiddleware, // for dispatching history actions
        thunk.withExtraArgument({ changeLanguage, createLogger }),
    ),
    ),
  );
  // hack to reload the reducer
  // IMPORTANT: that won't work to this file, and you'll get an error on the browser console
  // https://github.com/reduxjs/react-redux/releases/tag/v2.0.0
  // if( module.hot ){
  //   module.hot.accept(() => {
  //     const nextRootReducer = reducers
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

//----------------------------------------------------------------------------//

  // listeners

  // listen the changes on the redux store and persist them on the session
  // localStorageService.subscribe(
  //   store.subscribe,
  //   // defines a callback to get the data which will be persisted
  //   () => store.getState() 
  // );
  let me = localStorage.getItem('me');
  return (
    <ReduxProvider store={store}>
      <BrowserRouter history={history}>
        <Header />
        <Routes>
          <Route
            path="/"
            element={<ComplexPage/>}
          />

          <Route
            path="/view/:id"
            element={<JobsView/>}
          /> 

          <Route
            path="/signin/*"
            element={<SignIn/>}
          />


          <Route
            path="/tp/*"
            element={<ThirdParty/>}
          />
          <Route
            path="/forgot-password"
            element={<ForgotPassword/>}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword/>}
          />
          <Route
            path="/findjob"
            element={<FindJob/>}
          />
          <Route
            path="/viewjobs/*"
            element={<JobView/>}
          />
          <Route
            path="/apply"
            element={<Application/>}
          />
          <Route
            path="/scheduled"
            element={<Scheduled/>}
          />
          <Route
            path="/applyreview"
            element={<ReviewApply/>}
          />
          {/* <Route
            path="/communicate"
            element={<Communications/>}
          /> */}
          <Route
            path="/bank/*"
            element={<BankPage/>}
          /> 
          <Route
            path="/profile"
            element={<PublicProfile/>}
          /> 
          <Route
            path="/settings/*"
            element={<Settings/>}
          />  
          <Route 
            path="*" 
            element={<NotFound/>} 
          /> 
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;

