import React, { Component } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Button } from '../../../../common';

import './stripe.css'

class StripePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveCard: false,
      selectedBank: '',
    };
  }

  handleSaveCardChange = (event) => {
    this.setState({ saveCard: event.target.checked });
  };

  handleBankSelect = (event) => {
    this.setState({ selectedBank: event.target.value });
  };

  handlePaymentSubmit = async (event) => {
    event.preventDefault();
    const { saveCard, selectedBank } = this.state;
    const { stripe } = this.props;

    // You can add more validation here
    if (!stripe) {
      return;
    }

    const cardElement = this.props.elements.getElement(CardNumberElement);
    const expElement = this.props.elements.getElement(CardExpiryElement);
    const cvcElement = this.props.elements.getElement(CardCvcElement);

    // Create a PaymentMethod
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
        console.error(error);
        // Handle error
    } else {
        // Send paymentMethod.id and other data to your backend
        // for further processing and interaction with the Stripe API
        //   console.log(paymentMethod)
        sessionStorage.setItem('smp', JSON.stringify(paymentMethod));
        this.props.onSubmit()
    }
  };

  render() {
    const cancel = () => { 
        // e.preventDefault();
        window.location.pathname = '/bank'
    }
    return (
      <div className="stripe-payment">
          <label>
              Card details
              <CardNumberElement />
          </label>
          <label>
              Expiration date
              <CardExpiryElement  />
          </label>
          <label>
              CVC
              <CardCvcElement />
          </label>
          <div className="buttons">
            <Button
                large
                cancel
                type="button"
                title="Cancel"
                onClick={cancel}
            />
            <Button
                large
                title="Add Payment Method"
                onClick={this.handlePaymentSubmit}
            />
          </div>
      </div>
    );
  }
}

export default function WrappedStripePayment({ onSubmit }) {
  const stripe = useStripe();
  const elements = useElements();

  return <StripePayment onSubmit={onSubmit} stripe={stripe} elements={elements} />;
}
