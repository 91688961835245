// PaymentPortal.js

import React, { Component } from 'react';
import { Box, Typography, FormControl, RadioGroup, Radio, FormControlLabel, TextField } from '@mui/material';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';

import { getStripe } from '../../third-party/stripe/redux/stripe-actions'
import { UTILS, hasAuthTokenAsync } from '../../../lib';
import './payment-portal.css';
import { Button, Selector } from '../../../common';
import { createSubscription } from '../redux/account-action';
import { api } from '../../../config';
import Cookies from 'js-cookie';

class PaymentPortal extends Component {
  state = {
    selectedTier: 'tier1',
    totalAdditions: 10,
    additionalCredits: 0,
    tier: {},
    stripe: [],
    bank: [],
    me: null,
    accountType: ''
  };

  componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')):{ user: '' };
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      this.setState({ me: user });
      hasAuthTokenAsync()
      .then(() => {
        this.props.getStripe();
        api.get(`/accounts/bank/${id}`)
        .then(data => this.setState({ bank: data }))
        .catch(err => err);
      }).catch(err => err);
    })
  }

  onSelect = (e) => {
    e.preventDefault()
    window.location.pathname = '/bank/add'
  }

  get accountTypes() {
    // update to return payment methods
    return [
      {
        key: 0,
        value: '',
        display: 'Select',
      },
      ...this.state.bank.map(data => ({
        key: data.id,
        value: jwt.sign(data, 'N0!84NK5!'),
        display: `Ending in: ${String(data.cardNumber).substring(15)}`,
      })),
    ]
  }

  handleTierChange = (event) => {
    event.preventDefault()
    const selectedTier = event.target.value;
    const tiers = {
      tier1: { totalAdditions: 10, price: 1 },
      tier2: { totalAdditions: 20, price: 2 },
      tier3: { totalAdditions: 50, price: 5 },
    };
    this.setState({
      selectedTier,
      totalAdditions: tiers[selectedTier].totalAdditions,
    });
  };

  handleAdditionalCreditsChange = (event) => {
    const additionalCredits = parseInt(event.target.value, 10);
    const { selectedTier } = this.state;
    const stripe = JSON.parse(sessionStorage.getItem('stripe'))
    const t1 = stripe[3];
    const t2 = stripe[2];
    const t3 = stripe[1];
    const tiers = {
      tier1: { totalAdditions: 10, price: 1, tier: t1 },
      tier2: { totalAdditions: 20, price: 2, tier: t2 },
      tier3: { totalAdditions: 50, price: 5, tier: t3 },
    };
    const totalAdditions = isNaN(tiers[selectedTier].totalAdditions + additionalCredits) 
      ? tiers[selectedTier].totalAdditions : tiers[selectedTier].totalAdditions + additionalCredits;
    this.setState({
      additionalCredits: isNaN(additionalCredits) ? totalAdditions : additionalCredits,
      totalAdditions,
      tier: tiers[selectedTier]
    });
  };

  handlePaymentSubmit = (event) => {
    // Implement payment processing here
    event.preventDefault();
    // Calculate the total amount to be paid and proceed with the payment
    const { selectedTier, totalAdditions, additionalCredits } = this.state;
    const stripe = JSON.parse(sessionStorage.getItem('stripe'))
    const t1 = stripe[3];
    const t2 = stripe[2];
    const t3 = stripe[1];
    const tiers = {
      tier1: { totalAdditions: 10, price: 1, tier: t1 },
      tier2: { totalAdditions: 20, price: 2, tier: t2 },
      tier3: { totalAdditions: 50, price: 5, tier: t3 },
    };
    const totalAdditionPrice = totalAdditions * 0.1; // Each addition is 10 cents
    // console.log('tier: ', selectedTier, '\n\n', 'totalAds: ', totalAdditions,  '\n\n', 'price: ', totalAdditionPrice,  '\n\n', this.state.tier, '\n\n\n', stripe[0])
    // console.log(`Payment processing for Tier ${selectedTier}, Total Amount: $${totalAdditionPrice}`);
    // sessionStorage.setItem('payment-tier', selectedTier);
    // sessionStorage.setItem('additions', totalAdditions);
    // sessionStorage.setItem('total-price', totalAdditionPrice);
    // sessionStorage.setItem('tier', JSON.stringify(this.state.tier));
    // sessionStorage.setItem('bank-choice', this.state.accountType);
    // window.location.pathname = '/tp/pay'
    var ads = this.state.tier.price < totalAdditionPrice ? stripe[0] : {};
    // store our variables somewhere
    this.state.me.map(u => {
      const data = {
        ads,
        meid: u.id,
        phone: u.phone,
        email: u.email,
        selectedTier,
        totalAdditions,
        totalAdditionPrice,
        tier: this.state.tier,
        bank: this.state.accountType
      }
      // Call your payment gateway or API to process the payment
      this.props.createSubscription(data)
    })
  };

  handleCancel = (e) => {
    e.preventDefault();
    window.history.back()
  };

  render() {
    const { selectedTier, totalAdditions, additionalCredits } = this.state;
    const invalid = (
      this.state.accountType === ''
    )
    return (
      <Box className="paymentPortal">
        <Typography variant="h5">Payment Portal</Typography>
        <form onSubmit={this.handlePaymentSubmit}>
          <FormControl component="fieldset">
            <Typography variant="subtitle1">Select a Tier:</Typography>
            <RadioGroup value={selectedTier} onChange={this.handleTierChange}>
              {this.renderBody}
              <FormControlLabel value="tier1" control={<Radio />} label="Tier 1 - $1/month (10 additions)" />
              <FormControlLabel value="tier2" control={<Radio />} label="Tier 2 - $2/month (20 additions)" />
              <FormControlLabel value="tier3" control={<Radio />} label="Tier 3 - $5/month (50 additions)" />
            </RadioGroup>
          </FormControl>

          <Typography variant="subtitle1">Total Additions: {totalAdditions}</Typography>
          <Typography variant="subtitle1">Additional Credits (10 cents each):</Typography>
          <TextField
            type="number"
            value={additionalCredits}
            className="textField"
            onChange={this.handleAdditionalCreditsChange}
            InputProps={{
              inputProps: { min: 0, max: 100, step: 10 },
            }}
          />
          <h7>We will use the default Payment Method</h7>
          {/* <label>
            Select Account:
          </label>
          <Selector
            wide
            value={this.state.accountType}
            onSelect={i => this.setState({ accountType: i })}
            options={this.accountTypes}
          /> 
          <Button
            className="valueButton"
            type="button"
            title="Add New Account"
            onClick={this.onSelect}
          /> */}
          <Typography className="typography" variant="subtitle1">Total Amount: ${(totalAdditions * 0.1).toFixed(2)}</Typography>
          <div className='button-group'>
            <Button
              large
              cancel
              className="cancelButton"
              type="button"
              title="Cancel"
              onClick={this.handleCancel}
            />
            <Button
              large
              title="Pay Now"
              // inactive={invalid}
              className="submitButton"
              type="submit"
            />
          </div>
        </form>
      </Box>
    );
  }
}

const mapStateToProps = ({ stripeReducer }) => {
  const {
    stripeDisplay,
    error,
    loading, 
  } = stripeReducer

  return {
    stripe: stripeDisplay,
    error,
    loading, 
  }
}

const actions = {
  getStripe,
  createSubscription
}

export default connect(mapStateToProps, actions)(PaymentPortal);
