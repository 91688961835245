import React, { Component } from 'react';
import Router from './settings.router'

import styles from './settings.css'

class Settings extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default Settings;
