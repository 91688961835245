// SignIn.js

import React, { Component } from 'react';
// Redux
import { connect } from 'react-redux'
import { Box, Tab, Tabs, Typography } from '@mui/material';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';

// import MicrosoftLogin from 'react-microsoft-login';
// import AmazonLogin from 'react-amazon-login';

import {
  login,
  sendSMS,
  emailChanged,
  passwordChanged,
} from './redux/signin-action';
import './signin.css';
import { VerifyPhoneModal } from '../../shared';
import PhoneInput from 'react-phone-input-2';

class SignIn extends Component {
  state = {
    selectedTab: 0,
    selectedPortal: 'freelancer', // Default to Employee Portal
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    visitModal: null,
  };

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handlePortalChange = (event) => {
    this.setState({ selectedPortal: event.target.value });
  };
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };
  handlePasswordConfirm = (event) => {
    this.setState({ confirmPassword: event.target.value });
  };

  handleSignInSubmit = () => {
    // Implement login functionality here
    const deviceToken = localStorage.getItem('otp');
    const {
      email,
      password,
      selectedPortal,
      phoneNumber
    } = this.state;
    const data = { phone: phoneNumber, password, deviceToken, selectedPortal }
    this.props.login(data)
  };

  handleSignUpSubmit = (event) => {
    const {
      email,
      password,
      selectedPortal,
      confirmPassword
    } = this.state;
    event.preventDefault();
    localStorage.setItem("email", email);
    localStorage.setItem("portal", selectedPortal);
    password === confirmPassword && localStorage.setItem("password", password);
    window.location.pathname = '/signin/join-fryklance';
  };

  handlePhoneVerification = (e) => {
    e.preventDefault();
    localStorage.setItem('phone', this.state.phoneNumber)
    this.setState({ visitModal: {} })
  }

  handlePhoneNumberChange = (phoneNumber) => {
    this.setState({ phoneNumber })
  };

  render() {
    const { selectedTab, selectedPortal } = this.state;
    localStorage.removeItem('token');
    const ThirdParty = () => {
      return (
        <div className="thirdPartyButtons">
          <FacebookLogin
            appId="your-facebook-app-id"
            autoLoad={false}
            fields="name,email,picture"
            callback={this.handleFacebookResponse}
            textButton="Continue with Facebook"
            size="small"
          />
          <GoogleLogin
            clientId="your-google-client-id"
            buttonText="Continue with Google"
            onSuccess={this.handleGoogleResponse}
            onFailure={this.handleGoogleResponse}
            cookiePolicy={'single_host_origin'}
            style={{ marginTop: '8px' }}
            isSignedIn={false}
          />
          {/* Add Microsoft and Amazon sign-up buttons */}
          {/* <MicrosoftLogin
            clientId="your-microsoft-client-id"
            authCallback={this.handleMicrosoftResponse}
            buttonTheme="dark_short"
            children={<span>Continue with Microsoft</span>}
          /> */}
          {/* <AmazonLogin
            clientId="your-amazon-client-id"
            buttonText="Continue with Amazon"
            scope="profile"
            response_type="code"
            onSignIn={this.handleAmazonResponse}
            onError={this.handleAmazonResponse}
            className="amazon-button"
          /> */}
          {/* Add other sign-up buttons here (e.g., Twitter, etc.) */}
        </div>
      );
    };

    return (
      <div>
        {/* Modal */}
        <VerifyPhoneModal
          content={this.state.visitModal}
          onClickAway={() => this.setState({visitModal: null})}
          joinable={this.state}
          action={() => this.props.sendSMS()}
          onSubmit={this.handleSignInSubmit}
        />
        <Box className="root">
          <Tabs value={selectedTab} onChange={this.handleTabChange} centered>
            <Tab label="Login" />
            <Tab label="Sign Up" />
          </Tabs>
          {selectedTab === 0 && (
            <Box className="tabPanel">
              <Typography variant="h5">Log-In as
                <div className="portalSelection">
                  <select value={selectedPortal} onChange={this.handlePortalChange}>
                    {/* <option value="employee">Freelancer</option> */}
                    <option value="employer">Employer</option>
                  </select>
                </div>
              </Typography>
              <form className="formContainer" onSubmit={this.handlePhoneVerification}>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={this.state.phoneNumber}
                  onChange={this.handlePhoneNumberChange}
                  country={'us'}
                />
                <input onChange={this.handlePasswordChange} className="formInput" type="password" placeholder="Password" />
                <button type="submit" className="submitButton">
                  Login
                </button>
                {/* <ThirdParty /> */}
                <a href="/forgot-password">Forgot Password?</a>
              </form>
            </Box>
          )}
          {selectedTab === 1 && (
            <Box className="tabPanel">
              <Typography variant="h5">Sign-Up as
                <div className="portalSelection">
                  <select value={selectedPortal} onChange={this.handlePortalChange}>
                    {/* <option value="employee">Freelancer</option> */}
                    <option value="employer">Employer</option>
                  </select>
                </div>
              </Typography>
              <form className="formContainer" onSubmit={this.handleSignUpSubmit}>
                <input className="formInput" type="text" placeholder="Email" onChange={this.handleEmailChange} />
                <input className="formInput" type="password" placeholder="Password" onChange={this.handlePasswordChange} />
                <input className="formInput" type="password" placeholder="Confirm Password" onChange={this.handlePasswordConfirm} />
                <button type="submit" className="submitButton">
                  Sign Up
                </button>
                {/* <ThirdParty /> */}
              </form>
            </Box>
          )}
        </Box>
      </div>
    );
  }
}

const mapStateToProps = ({ signinReducer }) => {
  const {
    email,
    password,
    error,
    loading,
  } = signinReducer
  return {
    email,
    password,
    error,
    loading,
  }
}

const actions = {
  login,
  sendSMS,
  emailChanged,
  passwordChanged,
}

export default connect(mapStateToProps, actions)(SignIn);
