import React, { Component } from 'react';
import BrainTree from "./braintree/braintree";
import ThirdPartyRouter from './third-party.router';

class ThirdParty extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            visitModal: {},
         }
    }
    
    render() { 
        return ( 
            // <BrainTree
            //     content={this.state.visitModal}
            //     onClickAway={() => window.history.back()}
            //     onSubmit={this.handleFormSubmit}
            // />
            
            <ThirdPartyRouter />
         );
    }
}
 
export default ThirdParty;