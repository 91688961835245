import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import PaymentPage from './account-form/payment-form/payment-form';
// import { 
//   getTransactions, 
//   createAccount, 
//   deleteAccountById
//  } from '../../../actions/accounts';

import Router from './bank.router'
import styles from './bank-account.css'
import { hasAuthTokenAsync } from '../../lib';
import { api } from '../../config';
import Cookies from 'js-cookie';

class BankPage extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        onViewModal: null,
        paymentMethod: null,
        payment: {},
        me: null,
      }

    }

  componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')): { user: '' };
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      hasAuthTokenAsync()
      .then(() => {
        api.get(`/accounts/bank/${id}`)
        .then((data) => {
          this.bankData = data;
          // this.stripe = clientSecret
          // loadStripe(clientSecret)
        }).then(() => {
          const user = Cookies.get('me')?JSON.parse(Cookies.get('me')):'';
          // console.log(user);
          const [me] = user;
          const {
            id,
            phone
          } = me;
          api.get(`/me/${id}`)
          .then((data) => Cookies.set('me', JSON.stringify(data)));
        })

      }).catch(err => err)
    })
  }
  pk = loadStripe("pk_test_51Nc1B2K3O6lDYuYKlVXouO1IZQAEJR3vqmnCztkdmjPSyA9NuZsX6vSkDcp3DQOumSRS8omRYyQwkniQZvvBuax700HkcO1oUx")
  render() {
    return (
      <div className={styles.app}>
        <Router 
          data={this.bankData}
          payment={this.state.payment}
          onClickAway={() => this.setState({ paymentMethod: null })}
          onViewModal={() => this.setState({ paymentMethod: {}})}
        /> 
        {/* Modal create &stripe payment method  */}
        <PaymentPage
          stripePromise={this.pk}
          content={this.state.paymentMethod || this.props.paymentMethod}
          onClickAway={() => this.setState({ paymentMethod: null })}
          onSubmit={(e) => console.log(e)}
        />  
      </div>
    );
  }
}

const mapStateToProps = ({ accounts, auth }) => {
  const {
    accountsDisplay,
    transactions,
    loading,
    user,
    error,
  } = accounts

  const {
    isAdmin,
  } = auth

  return {
    accounts: accountsDisplay || {},
    transactions,
    loading,
    user,
    error,
    isAdmin,
  }
}

const actions = { 
  // getTransactions, 
  // createAccount, 
  // deleteAccountById 
}
// export default connect(mapStateToProps, actions)(BankPage);
export default BankPage;
