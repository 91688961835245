import * as accountsTypes from './account-types'

const {
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_FAILURE,
  CREATE_ACCOUNT_SUCCESS,
  FUND_ACCOUNT,
  FUND_ACCOUNT_SUCCESS,
  FUND_ACCOUNT_FAILURE,
  SET_ACCOUNT,
  GET_ACCOUNT,
  GET_ACCOUNT_FAILURE,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_SUCCESS,
  PATCH_ACCOUNT,
  PATCH_ACCOUNT_FAILURE,
  PATCH_ACCOUNT_SUCCESS,
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = accountsTypes

const INITIAL_STATE = {
  account: [],
  error: '',
  loading: false,
  accounts: [],
  accountsDisplay: [],
  transactions: [],
  receipt: null
}

export default (state = INITIAL_STATE, account) => {
  const {type, payload} = account
  switch (type) {
    case CREATE_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case FUND_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case FUND_ACCOUNT_SUCCESS:
      return {
        ...state,
        receipt: payload,
        loading: false,
      }
    case FUND_ACCOUNT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: payload,
      }
    case GET_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: payload,
        loading: false,
      }
    case GET_ACCOUNT_FAILURE:
      return {
        ...state,
        account: null,
        error: payload,
        loading: false,
      }
    case GET_ACCOUNTS:
      return {
        ...state,
        loading: true,
      }
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: payload,
        loading: false,
      }
    case GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: null,
        error: payload,
        loading: false,
      }
    case PATCH_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case PATCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: payload,
        loading: false,
      }
    case PATCH_ACCOUNT_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_TRANSACTION:
      return {
        ...state,
        loading: true,
      }
    case CREATE_TRANSACTION_FAILURE:
      return {
        ...state,
        account: payload,
        loading: false,
      }
    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
