import React from "react";
import { Routes, Route } from "react-router-dom";


import NotFound from '../not-found/not-found'
import SignIn from "./signin";
import JoinPortal from "./join/join-portal";

const SignInRouter = ({ props, onViewModal, payment }) => (
  <Routes>
    <Route
      index
      element={<SignIn/>}
    />

    <Route
        path="join-fryklance"
        element={<JoinPortal/>}
    />

    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
)

export default SignInRouter;
