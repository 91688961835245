import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

class PublicProfile extends Component {
  render() {
    // Replace the example user data with the actual data from the backend or props
    const userData = {
      name: 'John Doe',
      title: 'Full-stack Web Developer',
      location: 'New York, USA',
      bio: 'Passionate about building awesome web applications!',
      avatar: 'https://example.com/user_avatar.jpg', // URL to user's avatar image
      skills: ['React', 'Node.js', 'JavaScript', 'HTML', 'CSS'],
      portfolioLink: 'https://example.com/portfolio', // URL to user's portfolio or personal website
    };

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">Public Profile</Typography>
          </Toolbar>
        </AppBar>
        <div className="container">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Avatar alt={userData.name} src={userData.avatar} sx={{ width: 120, height: 120, margin: '0 auto' }} />
              <Typography variant="h5" gutterBottom align="center">
                {userData.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom align="center">
                {userData.title}
              </Typography>
              <Typography variant="body1" gutterBottom align="center">
                {userData.location}
              </Typography>
              <Typography variant="body2" gutterBottom align="center" color="textSecondary">
                {userData.bio}
              </Typography>
              <div className="skills">
                {userData.skills.map((skill, index) => (
                  <span key={index} className="skill">
                    {skill}
                  </span>
                ))}
              </div>
              <div className="portfolioLink">
                <Button variant="outlined" color="primary" href={userData.portfolioLink} target="_blank">
                  Visit Portfolio
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default PublicProfile;
