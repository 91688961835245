import React, {Component} from 'react';

class Name extends Component {
	render() {
		return(
			<h3>{this.props.name}</h3>
		);
	}
}

export default Name;