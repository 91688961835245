export const SET_JOB = 'set_job'
export const GET_JOB = 'get_job'
export const GET_JOB_SUCCESS = 'get_job_success'
export const GET_JOB_FAILURE = 'get_job_failure'

export const GET_MY_JOBS = 'get_my_jobs'
export const GET_MY_JOBS_SUCCESS = 'get_my_jobs_success'
export const GET_MY_JOBS_FAILURE = 'get_my_jobs_failure'

export const GET_BULK_PATCH = 'get_bulk_patch'
export const GET_BULK_PATCH_SUCCESS = 'get_bulk_patch_success'
export const GET_BULK_PATCH_FAILURE = 'get_bulk_patch_failure'

export const GET_GENDER = 'product_get_gender'
export const GET_GENDER_SUCCESS = 'product_get_gender_success'
export const GET_GENDER_FAILURE = 'product_get_gender_failure'

export const GET_LENGTH = 'product_get_length'
export const GET_LENGTH_SUCCESS = 'product_get_length_success'
export const GET_LENGTH_FAILURE = 'product_get_length_failure'

export const GET_SIZE = 'product_get_size'
export const GET_SIZE_SUCCESS = 'product_get_size_success'
export const GET_SIZE_FAILURE = 'product_get_size_failure'

export const CREATE_LISTING = 'create_job_listing'
export const CREATE_LISTING_FAILURE = 'create_job_listing_failure'
export const CREATE_LISTING_SUCCESS = 'create_job_listing_success'

export const ADD_QUANTITIES = 'add_quantities_product'
export const ADD_QUANTITIES_SUCCESS = 'add_quantities_product_success'
export const ADD_QUANTITIES_FAILURE = 'add_quantities_product_failure'

export const SUB_QUANTITIES = 'sub_quantities_product'
export const SUB_QUANTITIES_SUCCESS = 'sub_quantities_product_success'
export const SUB_QUANTITIES_FAILURE = 'sub_quantities_product_failure'

export const EXPORT_PRODUCTS = 'export_products'
export const EXPORT_SUCCESS = 'export_products_success'
export const EXPORT_FAILURE = 'export_products_failure'
export const IMPORT_PRODUCTS = 'import_products'
export const IMPORT_SUCCESS = 'import_products_success'
export const IMPORT_FAILURE = 'import_products_failure'

export const UPLOAD_FILE = 'upload_file'
export const UPLOAD_FILE_PROGRESS = 'upload_file_progress'
export const UPLOAD_FILE_SUCCESS = 'upload_file_success'
export const UPLOAD_FILE_FAILURE = 'upload_file_failure'

export const PATCH_PRODUCT = 'patch_product'
export const PATCH_PRODUCT_SUCCESS = 'patch_product_success'
export const PATCH_PRODUCT_FAILURE = 'patch_product_failure'

export const DELETE_PRODUCT = 'delete_product'
export const DELETE_PRODUCT_SUCCESS = 'delete_product_success'
export const DELETE_PRODUCT_FAILURE = 'delete_product_failure'

export const SET_CHECKED_PRODUCTS = 'set_checked_products'
export const SET_PRODUCTS_EXPENSES = 'set_products_expenses'
export const FILTER_PRODUCTS = 'filter_products'
export const FILTER_SUCCESS = 'filter_success'
export const FILTER_FAILURE = 'filter_failure'
