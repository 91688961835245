import React, {Component} from 'react';

class WorkInfo extends Component {
	render() {
		return(
			<h4>{this.props.position}</h4>
		);
	}
}

export default WorkInfo;