import { api } from '../../../config'

export default {
  getAll() {
    const url = '/accounts'
    return api.get(url)
  },

  post(data, id) {
    const url = `/accounts/${id}`
    return api.post(url, data)
  },

  fundAccount(data) {
    const url = '/accounts/fund'
    return api.post(url, data)
  },

  postSubscription(data) {
    const url = '/accounts'
    return api.post(url, data)
  },

  getById(cid) {
    const url = `/accounts/${cid}`
    return api.get(url)
  },

  patchById(update) {
    const url = '/accounts'
    return api.put(url, update)
  },

  patchAccount(update) {
    const url = '/accounts/add'
    return api.put(url, update)
  },

  deleteById(id) {
    const url = `/accounts/${id}`
    return api.delete(url, id)
  }
}
