// TEAM FLOW
export const GET_CONTACTS = 'main_get_contacts'
export const GET_CONTACTS_SUCCESS = 'main_get_contacts_success'
export const GET_CONTACTS_FAILURE = 'main_get_contacts_failure'
export const FILTER_CONTACTS = 'main_filter_contacts'

export const BRAINTREE_PAYMENT = 'braintree_payment'
export const BRAINTREE_PAYMENT_SUCCESS = 'braintree_payment_success'
export const BRAINTREE_PAYMENT_FAILURE = 'braintree_payment_failure'

export const DELETE_CONTACT = 'delete_contact'
export const DELETE_CONTACT_SUCCESS = 'delete_contact_success'
export const DELETE_CONTACT_FAILURE = 'delete_contact_failure'

export const SET_CONTACT = 'set_contact'

export const GET_CONTACT = 'get_contact'
export const GET_CONTACT_SUCCESS = 'get_contact_success'
export const GET_CONTACT_FAILURE = 'get_contact_failure'

export const PATCH_CONTACT = 'patch_contact'
export const PATCH_CONTACT_SUCCESS = 'patch_contact_success'
export const PATCH_CONTACT_FAILURE = 'patch_contact_failure'

export const CREATE_NOTE = 'create_note'
export const CREATE_NOTE_SUCCESS = 'create_note_success'
export const CREATE_NOTE_FAILURE = 'create_note_failure'

export const CLEAR_ERRORS = 'contact_clear_errors'
export const CLEAR_STATE = 'contact_clear_state'
