import React, { Component } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, TextField, Button } from '@mui/material';

import './forgot-password.css'

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      successMessage: '',
      errorMessage: '',
    };
  }

  handleForgotPassword = () => {
	window.location.pathname = "/signin"
    // Implement forgot password logic here
    // Send the email to reset password
    // Handle success and error messages accordingly
  };

  render() {
    const { email, successMessage, errorMessage } = this.state;

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Forgot Password
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Forgot Your Password?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Enter the email address associated with your account and we will send you a link to reset your password.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              {successMessage && (
                <Typography variant="body1" color="success">
                  {successMessage}
                </Typography>
              )}
              {errorMessage && (
                <Typography variant="body1" color="error">
                  {errorMessage}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" onClick={this.handleForgotPassword}>
                Send Reset Link
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ForgotPassword;
