import React, { Component } from 'react';

import { Input, Span } from "../../../common";

import './setting-page.css'
import Accounts from '../../bank-account/accounts/accounts';

class Setting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			avatar: '',
			accountDetails: null,
			SidebarProfile: null,
			baseUrl: window.location.href,
			token: sessionStorage.getItem("currentUser")? sessionStorage.getItem("currentUser") : '',
			data: {
				token : sessionStorage.getItem("currentUser")? sessionStorage.getItem("currentUser") : '',	
			}
		}
	}

	getUserProfiledetails = (token) => {
		return new Promise((resolve, reject) => {
			fetch({ 
				url: this.state.baseUrl+'/fryklance/profiledetails?token='+token,
				method: "GET",
				body: token,
				headers: '',
			}).then((res) => resolve(res));
		});
	};

	getUserbankDetails = (token) => {
		return new Promise((resolve, reject) => {
			fetch({ 
				url: this.state.baseUrl+'/account/details?token='+token,
				method: "GET",
				headers: ''
			}).then((res) => resolve (res));
		}); 
	};

	makeDefaultBankInformation = (data, token) => {
		return new Promise((resolve, reject) => {
			fetch({ 
				url: this.state.baseUrl+'/account/default?token='+token,
				method: "POST",
				data,
				headers: 'headers'
			}).then((res) => resolve (res));
		});
	};
	// service.getUserProfiledetails = function(data){
	// 	return $http({ 
	// 		url: baseUrl+'/fryklance/profiledetails?token='+ $AuthenticationService.token,
	// 		method: "GET",
	// 		data: $.param(data),
	// 		headers: headers
	// 	});
	// };
	
	getUserProfile = () => {
		let userDetail, license;
		if(typeof this.state.SidebarProfile == "undefined"){
		 this.getUserProfiledetails(this.state.token).then((response, status, headers, config) => {			
			 if(response.data.status) {
				 userDetail = response.data.data.user;
				 license = response.data.data.user.license+'?p='+Math.random();
				 this.setState({ SidebarProfile: userDetail });
				 this.setState({ avatar: userDetail.avatar+'?q='+Math.random() });
			 }
		 });
		}else{
			userDetail = this.state.SidebarProfile;
			license = userDetail.license;
		}

		this.getUserbankDetails(this.state.token)
		.then(function (response, status, headers, config) {
			let userAccountDetails;
		 if(response.data.status) {
			 userAccountDetails = response.data.data;
			 this.setState({ accountDetails: userAccountDetails })
		 }
	 });
	};

	count = () => {
		var a = 0;
		// console.log(a++)
	}

	makeDefault = (id) => {
		var data = {
			account_id : id
		}
		this.makeDefaultBankInformation(data, this.state.token).then((response) => {
			const {
				userAccountDetails
			} = this.props
			if(response.data){
				if(response.data.status){
					Array.isArray(userAccountDetails) && userAccountDetails.forEach((value, key) => {
						if(value.id === response.data.data[0].id){
							userAccountDetails[key].is_default = 1;
						}else{
							userAccountDetails[key].is_default = 0;
						}
					});
					this.SetState({ accountDetails: userAccountDetails });
				}else{
					Error('Something went wrong. Please try again.')
					// bankInfoNotification('Something went wrong. Please try again.', 'alert-danger');
				}
			}
		})
	}
		// 	}else{
		// 		bankInfoNotification('Something went wrong. Please try again.', 'alert-danger');
		// 	}
		// }, function(response){
		// 	bankInfoNotification('Something went wrong. Please try again.', 'alert-danger');
		// });

		// function bankInfoNotification(msg, classText){
		// 	bankInfoNotification = msg;
		// 	timeout(function () { bankInfoNotification = false; }, 5000);
		// }
	// }

	// (function () { return sharedDataService.getSidebarProfile(); }, function (newValue, oldValue) {
	// 	if (newValue !== oldValue){
	// 		userDetail = newValue;
	// 	}
	// });
	
	submitSettingForm = function(userDetail, isValid) {
		if(isValid === true){
			this.props.loading = true;
			var settingData = { 
				first_name : userDetail.first_name,
				last_name : userDetail.last_name,
				phone : userDetail.phone,
				address1 : userDetail.address1,
				address2 : userDetail.address2,
				city : userDetail.city,
				zipcode : userDetail.zipcode,
				state : userDetail.state,
			};
			
			if(typeof userDetail.license == "object"){
				settingData.license = userDetail.license;
				var file = settingData.license;
			
				// file.upload = Upload.upload({
				// 	url: baseUrl+'/profile?token='+token,
				// 	data: settingData,
				// });

				// file.upload.then(function (response) {
				// 	loading = false;
				// 	if(response.data.status){
				// 		userDetail = response.data.data["0"];
				// 		license = response.data.data["0"].license+'?p='+Math.random();
				// 		profileInfoNotification("Your profile is successfully updated.", "alert-success");
				// 	} else {
				// 		profileInfoNotification("Your profile is fail to updated.", "alert-danger");
				// 	}
				// }, function (response) {
				// 	loading = false;
				// 	if (response.status > 0) {
				// 		profileInfoNotification("Your profile is fail to updated.", "alert-danger");
				// 	}
				// }, function (evt) {
				// 	file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
				// });
			// }else{
			// 	dataFactory.updateUserProfiledetails(settingData, token).then(function(response){
			// 		loading = false;
			// 		userDetail = response.data.data["0"];
			// 		profileInfoNotification("Your profile is successfully updated.", "alert-success");
			// 	},function(response){
			// 		loading = false;
			// 		profileInfoNotification("Your profile is fail to updated.", "alert-danger");
			// 	});
			// }
			}
		}

		// function profileInfoNotification(msg, classText){
		// 	message = msg;
		// 	ngclass = classText;
		// 	$timeout(function () { message = false; }, 5000);
		// }
	};

	onClick = () => {
		window.location.pathname = '/bank/add'
	}

	handleResetPassword = () => {
		window.location.pathname = '/reset-password'
	}

	// redirect = function(page){
	// 	location.pathname(page);
	// 	return false;
	// };

	// getFile = function () {
	// 	progress = 0;
	// 	fileReader.readAsDataUrl(file, _scope).then(function(result) {
	// 		license = result;
	// 	});
	// };
	render() { 
		const fileSelect = function(_scope,el){
			// el.bind("change", function(e){
			// 	file = (e.srcElement || e.target).files[0];
			// 	getFile();
			// })
		}
		const detailsPopup = () => {
			return (
				<div class="Price-modal aligncenter">
					<div class="modal-header">
						<h2 class="adjust-price">Bank Information</h2>
					</div>
					<form name="accountForm" onSubmit="submitAccountForm(accountForm.$valid, account)" novalidate>
						<div class="alert alert-danger">
							{'errorMsg'}
						</div>
						<div class="frm">
							<label class="label">Bank Name</label>
							<Input type="text" name="bank_name" class="input-feild" required/>
							<em /*ng-show="accountForm.bank_name.$error.required && !accountForm.bank_name.$pristine"*/ class="error">Enter bank name.</em>
						</div>
						<div class="frm">
							<label class="label">Account Number</label>
							<Input type="text" name="account_number" class="input-feild" maxlength="20"/>
							<em /*ng-show="(accountForm.account_number.$error.pattern || accountForm.account_number.$error.required) && !accountForm.account_number.$pristine"*/ class="error">Enter a valid account number.</em>
						</div>
						<div class="frm">
							<label class="label">Routing Number</label>
							<Input type="text" name="routing_number" class="input-feild" maxlength="25"/>
							<em /*ng-show="(accountForm.routing_number.$error.pattern || accountForm.routing_number.$error.required) && !accountForm.routing_number.$pristine"*/ class="error">Enter a valid routing number.</em>
						</div>

						<div class="modal-footer">
							<button class="button btnLightBlue btnCols fl" type="button" onClick={this.props.cancel}>Cancel</button>
							<button class="button btnGreen btnCols fr" type="submit">Submit</button>
						</div>
					</form>    
				</div>
			)
		}
		return ( 
			<div class="inner_content">
				<div class="innerPage_container">
					<header class="afterLoginHeader clearfix">
						<div class="LeftHeader">
							<h2>Settings</h2>
							<p>Make changes to your account information.</p>    
						</div>
					</header>
					<div class="page_container">
						<div class="alert alert-dismissible {{ngclass}}">
							{'alert'}
						</div>
						<div class="containbox_shadow">
							<form name="settingForm" novalidate enctype="multipart/form-data">
								<div class="settingcontent_block border-bottom">
									<div class="setting-block">
										<div class="textalignleft">
											<h3>Account</h3>
										</div>
										<div class="form">
											<div class="row">
												<div class="span3 license">
													<div class="frm">
														<label class="label">
															<Span>DRIVER’S LICENSE 
																<div class="edit_icon">
																	<a href="0">
																		<img src="app/resources/images/edit.svg" alt=""/>
																	</a>
																</div>
															</Span>
														</label>
														<div class="inputFile">
															<Input type="file" name="file" accept="image/*" required title="Update Driver's License Photo"/>
															<div class="addfile fullimage">
																<div class="addfile-icon">
																	<div class="driver-licence editdriver-licence">
																		<img alt=""/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<br />
												<div class="span6">
													<Span className="row">
														<div class="span2">
															<div class="frm">
																<label class="label">FIRST NAME</label>
																<Input type="text" class="input-feild" name="first_name" required/>
																<em class="error">Enter a first name.</em>
															</div>
														</div>
														<div class="span2">
															<div class="frm">
																<label class="label">LAST  NAME</label>
																<Input type="text" class="input-feild" name="last_name" required/>
																<em class="error">Enter a last name.</em>
															</div>
														</div>
													</Span>
													<Span className="row">
														<div class="span2">
															<div class="frm">
																<label class="label">EMAIL ADDRESS</label>
																<Input type="text" class="input-feild" name="email" required/>
																<em class="error">Enter a Email address.</em>
															</div>
														</div>
														<div class="span2">
															<div class="frm">
																<label class="label">PHONE NUMBER</label>
																<Input type="text" class="input-feild" maxlength="10" name="phone"/>
																<em class="error">Enter a valid number.</em>
															</div>
														</div>
													</Span>
												</div>
											</div>
											<Span className="row">
												<div class="span2">
													<div class="frm">
														<label class="label">ADDRESS LINE 1</label>
														<Input type="text" class="input-feild" name="address1" required/>
														<em class="error">Enter an address.</em>
													</div>
												</div>
												<div class="span2">
													<div class="frm">
														<label class="label">ADDRESS LINE 2</label>
														<Input type="text" class="input-feild"/>
													</div>
												</div>
											</Span>
											<Span className="row">
												<div class="span2">
													<div class="frm">
														<label class="label">CITY</label>
														<Input type="text" class="input-feild" name="city" required maxlength="30"/>
														<em class="error">Enter city.</em>
													</div>
												</div>
												<div class="span2">
													<div class="row"> 
														<div class="span2">
															<div class="frm">
																<label class="label">STATE</label>
																<Input type="text" class="input-feild" name="state" required/>
																<em class="error">Enter a valid state.</em>
															</div>
														</div>
														<div class="span2">
															<div class="frm">
																<label class="label">ZIP CODE</label>
																<Input type="text" class="input-feild" name="zipcode" required maxlength="5"/>
																<em class="error">Enter a valid zip code.</em>
															</div>
														</div>
													</div>
												</div>
											</Span>
										</div>                  
									</div>
									<div className='settingb_group'>
										<div className="setting_button clearfix">
											<button type="submit" class="active fr" value="Next">Update Profile <loading></loading></button>
										</div>
										<div className="setting_button clearfix">
											<button onClick={this.handleResetPassword} class="active fr">Reset Password <loading></loading></button>
										</div>
									</div>
								</div>
							</form>
							<div class="settingcontent_block border-bottom">
								<div class="setting-block">
									<div class="textalignleft">
										<h3>Bank Information</h3>
									</div>
									<div class="alert alert-dismissible">
										{/*bankInfoNotification*/}
									</div>
									<div class="BankDetails">
										<Accounts />
									</div>                      
								</div>
							</div>
						</div>  
					</div>
				</div>  
			</div>
		 );
	}
}
 
export default Setting;

