/* eslint-disable import/no-anonymous-default-export */
import * as stripeTypes from './stripe-types'

const {
  CREATE_STRIPE,
  CREATE_STRIPE_FAILURE,
  CREATE_STRIPE_SUCCESS,
  SET_STRIPE,
  GET_STRIPE,
  GET_STRIPE_FAILURE,
  GET_STRIPE_SUCCESS,
  GET_PLAN,
  GET_PLAN_FAILURE,
  GET_PLAN_SUCCESS,
  PATCH_STRIPE,
  PATCH_STRIPE_FAILURE,
  PATCH_STRIPE_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = stripeTypes

const INITIAL_STATE = {
  stripeDisplay: {},
  selectedPlan: [],
  stripes: [],
  error: '',
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action
  const {data} = {...payload}
  switch (type) {
    case CREATE_STRIPE:
      return {
        ...state,
        loading: true,
      }
    case CREATE_STRIPE_SUCCESS:
      return {
        ...state,
        stripes: payload,
        loading: false,
      }
    case CREATE_STRIPE_FAILURE:
      return {
        ...state,
        error: payload,
        stripes: payload,
        loading: false,
      }
    case SET_STRIPE:
      return {
        ...state,
        stripe: payload,
      }
    case GET_STRIPE:
      return {
        ...state,
        loading: true,
      }
    case GET_STRIPE_SUCCESS:
      return {
        ...state,
        stripeDisplay: data,
        loading: false,
      }
    case GET_STRIPE_FAILURE:
      return {
        ...state,
        stripeDislpay: null,
        error: payload,
        loading: false,
      }
    case GET_PLAN:
      return {
        ...state,
        loading: true,
      }
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        selectedPlan: payload,
        loading: false,
      }
    case GET_PLAN_FAILURE:
      return {
        ...state,
        selectedPlan: null,
        error: payload,
        loading: false,
      }
    case PATCH_STRIPE:
      return {
        ...state,
        loading: true,
      }
    case PATCH_STRIPE_SUCCESS:
      return {
        ...state,
        stripe: payload,
        loading: false,
      }
    case PATCH_STRIPE_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
