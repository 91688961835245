import React, { Component } from 'react';
import { connect } from 'react-redux'
import { 
  Button,
  Form,
  Header,
  Input,
  FloatBox
} from '../../../../common'

// Styles
import styles from './CheckoutMain.css'

class CheckoutMain extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {loading} = this.props
    return (
      <div className={styles.container}>
        <FloatBox>
          <h2>Thank You!</h2>
          <Form>
          {
          // <h4>Sign Up {this.props.company.name && `- ${this.props.company.name}`}</h4>
          }
          </Form>
          {
            // <FormValidationErrors validation={validation} />
            // <FormSubmitErrors errors={this.state.errors} />
          }
        </FloatBox>
      </div>
    );
  }
}

const mapStateToProps = ({ stripe }) => {
  const {
    plan,
    stipeDisplay,
    error,
    loading, 
  } = stripe

    return {
      plan,
      stripe:stipeDisplay,
      error,
      loading, 
  }
}

const actions = {
}

export default connect(mapStateToProps, actions)(CheckoutMain);
