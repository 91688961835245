import React from "react";
import { Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import AddAccount from "./account-form/account-form";
import AccountsView from "./accounts/accounts";
import InAppPaymentPortal from "./make-payment/make-payment";
import PaymentPortal from "../bank-account/payment-portal/payment-portal";

import NotFound from '../not-found/not-found'
import BankPage from "./bank-account";
import { push } from "connected-react-router";

const BankPageRouter = ({ props, onViewModal, onClickAway, payment, data }) => (
  <Routes>
    <Route
      index
      element={<AccountsView data={data} />}
    />

    <Route
      path="add"
      element={<AddAccount
        props={props}
        onViewModal={onViewModal}
        payment={payment}
      />}
    />

    <Route
      path="add-credits"
      element={<InAppPaymentPortal
        props={props}
        payment={payment}
      />}
    />

    <Route
        path="payment"
        element={<PaymentPortal/>}
    />
    
    <Route
        path="one-time"
        element={<BankPage onClickAway={onClickAway} paymentMethod={onViewModal}/>}
    />

    {/* <Route
      path=":id"
      element={<Account
        props={props}
      />}
    /> */}

    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
)

export default BankPageRouter;
