import React, { Component } from 'react';
import { connect } from 'react-redux'
import validator from 'validator'
import cookies from 'js-cookie'

import {
  Button,
  Header,
  Body,
  Input,
  Form,
} from '../../../common'

// Actions

import styles from './profile.css'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  // static getDerivedStateFromProps(nextProps) {
  //   return nextProps.me || null
  // }

  get initialState() {
    const me = cookies.get('me') ? cookies.get('me') : ''
    const {
      firstName,
      lastName,
      email,
      title,
      role,
    } = me

    return {
      editing: false,
      valid: true,
      firstName,
      lastName,
      title,
      email,
      role,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    }
  }

  setEditState(editing) {
    this.setState({
      ...this.initialState,
      editing,
    })
  }

  onSubmit(e) {
    e.preventDefault()
    if (!this.formIsValid) {
      return
    }

    const {
      firstName,
      lastName,
      username,
      title,
      email,
      oldPassword,
      newPassword,
    } = this.state

    const update = {
      firstName,
      lastName,
      username,
      title,
      email,
      oldPassword,
      password: newPassword,
    }
    this.setEditState(false)
    this.props.patchSelf(update)
  }

  get passwordIsValid() {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    } = this.state
    const passwordsAreEmpty = !(
      oldPassword
      || newPassword
      || newPasswordConfirm
    )

    const newPasswordsMatch = (
      newPassword
      && newPassword.length >= 6
      && newPassword === newPasswordConfirm
    )

    return (
      passwordsAreEmpty
      || (
        oldPassword
        && oldPassword.length >= 6
        && newPasswordsMatch
      )
    )
  }

  get formIsValid() {
    const {
      firstName,
      lastName,
      role,
      email,
    } = this.state

    const isValid = (
      firstName
      && lastName
      // email is valid or the user is a supervisor
      && (validator.isEmail(email || '') || role === 'supervisor')
      && this.passwordIsValid
    )

    return isValid
  }

  render() {
    const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
    const {
      name,
      email,
      title,
      company,
      role,
      oldPassword,
      newPassword,
      newPasswordConfirm,
    } = me

    const invalid = !this.formIsValid

    const isMember = role && (role === 'Admin' || role === 'Rep')

    return (
      <div>
        <Header>
          <h3>{name}</h3>
        </Header>
        <Body className="body">
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <label>
              Your Name:
            </label>
            <Input
              placeholder={name}
              onChange={name => this.setState({ name })}
            />

            <br />

            {isMember && (
              <label>
                Title:
              </label>
            )}

            {isMember && (
              <Input
                placeholder={title}
                onChange={title => this.setState({ title })}
              />
            )}

            {isMember && (
              <br />
            )}

            {isMember && (
              <label>
                Email:
              </label>
            )}

            {isMember && (
              <Input
              placeholder={email}
                onChange={email => this.setState({ email })}
              />
            )}

            {isMember && (
              <br />
            )}

            <label>
              ID:
            </label>

              {me.id}
            
            <label>
              Auth:
            </label>
            <Input
              placeholder={role}
              onChange={role => this.setState({ role })}
            />

            <h4>
              Change Password
            </h4>

            <Input
              placeholder="Old Password"
              type="password"
              value={oldPassword}
              onChange={oldPassword => this.setState({ oldPassword })}
            />

            <div className="separator"> </div>

            <Input
              placeholder="New Password"
              type="password"
              value={newPassword}
              onChange={newPassword => this.setState({ newPassword })}
            />

            <div className="separator"> </div>

            <Input
              placeholder="Confirm Password"
              type="password"
              value={newPasswordConfirm}
              onChange={newPasswordConfirm => this.setState({ newPasswordConfirm })}
            />

            <div className="separator"> </div>
            <div className="separator"> </div>

            <div className="inlineBtn">
              <Button
                small
                type="submit"
                title="UPDATE PROFILE"
                inactive={invalid}
              />
              
              <Button
                small
                type="submit"
                title="UPDATE PAYMENT METHOD"
                link='/profile/card'
                // inactive={invalid}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = () => {
  const {
    loading
  } = {}
  return {
    loading,
  }
}

const actions = {
}

// export default connect(mapStateToProps, actions)(Profile)
export default Profile;
