const { NODE_ENV } = process.env

const isProd = true;

// const prod = 'api.jobaround.us'
const prod = 'api.pheanom.us'
const local = 'localhost' 
// const local = '192.168.50.254'
// const local = '172.16.1.206'

// const uri = `https://${prod}`
const uri = isProd ?`https://${prod}` : `http://${local}:1337`

export default uri
