// Router
import { push } from 'react-router-redux'

// Types
import * as thirdpTypes from './thirdp-types'

// Services
import thirdp from './thirdp-service'

import cookies from 'js-cookie';

const {
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  FILTER_CONTACTS,
  BRAINTREE_PAYMENT,
  BRAINTREE_PAYMENT_SUCCESS,
  BRAINTREE_PAYMENT_FAILURE,
  SET_CONTACT,
  GET_CONTACT,
  GET_CONTACT_FAILURE,
  GET_CONTACT_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAILURE,
  DELETE_CONTACT_SUCCESS,
  PATCH_CONTACT,
  PATCH_CONTACT_FAILURE,
  PATCH_CONTACT_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = thirdpTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}
const { id, company } = me
const uid=id,cid=company;

// Contacts
export const getContacts = () => dispatch => {
  dispatch({
    type: GET_CONTACTS,
  })

  thirdp.getAll(cid,uid)
    .then(res => onGetContactsSuccess({dispatch, res}))
    .catch(err => onGetContactsFailure({dispatch, err}))
}

function onGetContactsSuccess({dispatch, res}) {
  console.log('getContacts success');
  dispatch({
    type: GET_CONTACTS_SUCCESS,
    payload: res.data,
  })
}

function onGetContactsFailure({dispatch, err}) {
  console.log('getContacts failure', err);
  dispatch({
    type: GET_CONTACTS_FAILURE,
    payload: err.message || 'Get Contacts Failed',
  })
}

export const filterContacts = input => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { thirdp } = getState().thirdp
  const thirdpDisplay = !search ? thirdp : thirdp.filter(contact => {
    const name = contact.name.toLowerCase()
    return name.includes(search)
  })

  dispatch({
    type: FILTER_CONTACTS,
    payload: thirdpDisplay,
  })
}

export const filterContactsByRep = input => (dispatch, getState) => {
  const search = input.toLowerCase().trim()
  const { thirdp } = getState().thirdp
  const thirdpDisplay = !search ? thirdp : thirdp.filter(contact => {
    const rep = contact.rep
    return rep.includes(search)
  })

  dispatch({
    type: FILTER_CONTACTS,
    payload: thirdpDisplay,
  })
}

export const braintreePayment = payload => dispatch => {
  dispatch({
    type: BRAINTREE_PAYMENT,
  })
  thirdp.post(payload)
    .then(res => onBraintreePaymentSuccess({dispatch, res}))
    .catch(err => onBraintreePaymentFailure({dispatch, err}))
}

function onBraintreePaymentSuccess({dispatch, res}) {
  console.log('BraintreePayment success');
  dispatch({
    type: BRAINTREE_PAYMENT_SUCCESS,
  })
  dispatch(push('/applyreview'))
}

function onBraintreePaymentFailure({dispatch, err}) {
  console.log('BraintreePayment failure', err);
  const payload = err.message || 'BraintreePayment Failed'
  window.alert(payload)
  dispatch({
    type: BRAINTREE_PAYMENT_FAILURE,
    payload,
  })
}

export const setContact = payload => {
  sessionStorage.setItem('select_contact', JSON.stringify(payload))
  return {
    type: SET_CONTACT,
    payload,
  }
}

export const getContactById = contactId => (dispatch, getState) => {
  // check if a contact has been set yet
  const { contact } = getState().thirdp
  if (contact && contact.id !== contactId) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }
  dispatch({
    type: GET_CONTACT,
  })
  thirdp.getById(contactId)
    .then(res => onGetContactSuccess({dispatch, res}))
    .catch(err => onGetContactFailure({dispatch, err}))
}

function onGetContactSuccess({dispatch, res}) {
  console.log('getContact success');
  dispatch({
    type: GET_CONTACT_SUCCESS,
    payload: res,
  })
}

function onGetContactFailure({dispatch, err}) {
  console.log('getContact failure', err);
  const payload = err.message || 'GetContact Failed'
  window.alert(payload)
  dispatch({
    type: GET_CONTACT_FAILURE,
    payload,
  })
  dispatch(push('/thirdp'))
}

export const patchContactById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_CONTACT,
  })
  thirdp.patchById(id, update)
    .then(res => onPatchContactSuccess({dispatch, res}))
    .catch(err => onPatchContactFailure({dispatch, err}))
}

function onPatchContactSuccess({dispatch, res}) {
  console.log('getContact success');
  dispatch({
    type: PATCH_CONTACT_SUCCESS,
    payload: res,
  })
}

function onPatchContactFailure({dispatch, err}) {
  console.log('patchContact failure', err);
  const payload = err.message || 'PatchContact Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_CONTACT_FAILURE,
    payload,
  })
}

// export const createNote = data => (dispatch, getState) => {
//   dispatch({
//     type: CREATE_NOTE,
//   })

//   const {
//     assignmentId,
//     text,
//   } = data

//   assignments.postNoteById(assignmentId, { text })
//     .then(res => onCreateNoteSuccess({dispatch, getState, res}))
//     .catch(err => onCreateNoteFailure({dispatch, err}))
// }

// function onCreateNoteSuccess({dispatch, getState, res}) {
//   console.log('createNote success');
//   const { note } = res
//   // update the assignments array on the contact

//   const { contact } = getState().thirdp
//   const { assignments } = contact
//   const assignment = assignments.find(el => el.id === note.assignmentId)
//   assignment.notes = [...assignment.notes, note]

//   dispatch({
//     type: CREATE_NOTE_SUCCESS,
//     payload: { ...contact },
//   })
// }

// function onCreateNoteFailure({dispatch, err}) {
//   console.log('createNote failure', err);
//   dispatch({
//     type: CREATE_NOTE_FAILURE,
//     payload: err.message || 'CreateNote Failed',
//   })
// }

export const deleteContactById = id => (dispatch, getState) => {
  dispatch({
    type: DELETE_CONTACT,
  })
  thirdp.deleteById(id)
    .then(res => onDeleteContactSuccess({dispatch, getState, res}))
    .catch(err => onDeleteContactFailure({dispatch, err}))
}

function onDeleteContactSuccess({dispatch, getState, res}) {
  console.log('deleteAssignment success');

  dispatch({
    type: DELETE_CONTACT_SUCCESS,
    payload: [res, ...getState /* probably should be some sort of data */],
  })
}

function onDeleteContactFailure({dispatch, err}) {
  console.log('deleteContact failure', err)
  const payload = err.message || 'deleteContact Failed'
  window.alert(payload)
  dispatch({
    type: DELETE_CONTACT_FAILURE,
    payload,
  })
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
