import React, { Component } from 'react';
import Router from './job-view.router'
import styles from './job-view.css'

class JobView extends Component {
  render() {
    return (
      <div className={styles.app}>
        <Router />
      </div>
    );
  }
}

export default JobView;
