/* eslint-disable import/no-anonymous-default-export */
import * as signinTypes from './signin-types';

const {
  CREATE_COMPANY,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_SUCCESS,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  SET_COMPANY,
  GET_COMPANY,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  PATCH_COMPANY,
  PATCH_COMPANY_FAILURE,
  PATCH_COMPANY_SUCCESS,
  EMAIL_CHANGED,
  ACCESS_CHANGED,
  USERNAME_CHANGED,
  PASSWORD_CHANGED,
  CONFIRM_PASSWORD_CHANGED,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  GET_SELF,
  GET_SELF_FAILURE,
  GET_SELF_SUCCESS,
  PATCH_SELF,
  PATCH_SELF_FAILURE,
  PATCH_SELF_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CREATE_PASSWORD,
  CREATE_PASSWORD_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CLAIM_ACCOUNT,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = signinTypes;

const INITIAL_STATE = {
  company: null,
  name: '',
  email: '',
  username: '',
  password: '',
  passwordConfirm: '',
  me: null,
  error: '',
  loading: false,
  isAdmin: false,
  accessCode: '',
  smsResponse: '',
  // formErrors: {
  //   email: '',
  //   password: '',
  //   response: '',
  // },
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case CREATE_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case CREATE_COMPANY_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SEND_SMS:
      return {
        ...state,
        loading: true,
      }
    case SEND_SMS_SUCCESS:
      return {
        ...state,
        smsResponse: payload,
        loading: false,
      }
    case SEND_SMS_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case SET_COMPANY:
      return {
        ...state,
        company: payload,
      }
    case GET_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        company: null,
        error: payload,
        loading: false,
      }
    case PATCH_COMPANY:
      return {
        ...state,
        loading: true,
      }
    case PATCH_COMPANY_SUCCESS:
      return {
        ...state,
        company: payload,
        loading: false,
      }
    case PATCH_COMPANY_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case EMAIL_CHANGED:
      return {
        ...state,
        email: payload,
      }
    case ACCESS_CHANGED:
      return {
        ...state,
        accessCode: payload,
      }
    case USERNAME_CHANGED:
      return {
        ...state,
        username: payload,
      }
    case PASSWORD_CHANGED:
      return {
        ...state,
        password: payload,
      }
    case CONFIRM_PASSWORD_CHANGED:
      return {
        ...state,
        passwordConfirm: payload,
      }
    case LOGIN:
      return {
        ...state,
        error: '',
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...INITIAL_STATE,
      }
    case LOGIN_FAILURE:
      return {
        ...INITIAL_STATE,
        error: payload,
      }
    case GET_SELF_SUCCESS:
      return {
        ...state,
        loading: true,
      }

    case GET_SELF:
      return {
        ...state,
        me: payload,
        isAdmin: payload.role === "Admin",
        loading: false,
      }
    case GET_SELF_FAILURE:
      return {
        ...state,
        me: null,
        isAdmin: false,
        loading: false,
        error: payload,
      }
    case PATCH_SELF:
      return {
        ...state,
        loading: true,
      }
    case PATCH_SELF_SUCCESS:
      return {
        ...state,
        me: payload,
        loading: false,
      }
    case PATCH_SELF_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case RESET_PASSWORD:
      return {
        ...state,
        password: '',
        error: '',
        loading: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
      }
    case CREATE_PASSWORD:
      return {
        ...state,
        error: '',
        loading: true,
      }
    case CLAIM_ACCOUNT:
      return {
        ...state,
        error: '',
        loading: true,
      }
    case CREATE_PASSWORD_SUCCESS:
      return {
        ...INITIAL_STATE,
      }
    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        password: '',
        passwordConfirm: '',
        error: payload,
        loading: false,
      }    
    case CLEAR_STATE:
      return {
        ...INITIAL_STATE,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: '',
        loading: false,
      }
    default:
      return state
  }
}
