import React, { Component } from 'react';
import { Container, Typography, Grid, Button, Modal, Box, TextField } from '@mui/material';
import { Rating } from '@mui/material';

class Scheduled extends Component {
  state = {
    scheduledJobs: [], // You would need to populate this array with data from an API or some other source.
    isPriceModalOpen: false,
    isRateModalOpen: true,
  };

  pricePopup = () => {
    this.setState({ isPriceModalOpen: true });
  };

  customerRate = () => {
    this.setState({ isRateModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isPriceModalOpen: false, isRateModalOpen: false });
  };

  renderScheduledJobs = () => {
    return this.state.scheduledJobs.map((job) => (
      <Grid item xs={12} key={job.id} className="scheduled-job">
        <div className="job-details">
          <Typography variant="h6">Job Details</Typography>
          <Typography>{job.description}</Typography>
          <div className="buttons-container">
            <Button variant="outlined" className="adjust-price-btn" onClick={this.pricePopup}>
              Adjust Price
            </Button>
            <Button variant="contained" className="mark-complete-btn" onClick={this.customerRate}>
              Mark as Complete
            </Button>
          </div>
        </div>
      </Grid>
    ));
  };

  render() {
    const modalStyles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
        borderRadius: '4px',
        maxWidth: '500px',
    };
    return (
      <Container className="scheduled-container">
        <Typography variant="h4" gutterBottom>
          Upcoming Jobs
        </Typography>
        <Grid container spacing={2}>
          {this.renderScheduledJobs()}
        </Grid>

        {/* Price Adjustment Modal */}
        <Modal open={this.state.isPriceModalOpen} onClose={this.handleCloseModal}>
          <Box sx={{ ...modalStyles }}>
            <Typography variant="h3" gutterBottom>
              Adjust Price
            </Typography>
            <form>
              <div className="form-group">
                <label htmlFor="newPrice">New Price</label>
                <TextField type="text" id="newPrice" variant="outlined" fullWidth />
              </div>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </form>
          </Box>
        </Modal>

        {/* Customer Rating Modal */}
        <Modal open={this.state.isRateModalOpen} onClose={this.handleCloseModal}>
          <Box sx={{ ...modalStyles }}>
            <Typography variant="h3" gutterBottom>
              Rate Customer
            </Typography>
            <div>
              {/* Rating component goes here */}
              {/* Add other elements for rating */}
              <Rating name="customerRating" />
            </div>
            <textarea rows="4" placeholder="Write your comment here..." />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Box>
        </Modal>
      </Container>
    );
  }
}

export default Scheduled;
