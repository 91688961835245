import React, { Component } from 'react';
import { connect } from 'react-redux'
import cookies from 'js-cookie'
import validator from 'validator'
// import { 
//   CardCVCElement, 
//   CardExpiryElement, 
//   CardNumberElement, 
//   Elements, 
//   PaymentElement,
//   LinkAuthenticationElement, 
//   StripeProvider 
// } from '@stripe/react-stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';

import {
  createAccount,
  patchAccountById
} from '../../redux/account-action';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
  FormModal,
  FileUpload,
  Icon,
} from '../../../../common'

import styles from './payment-form.css'
import { loadStripe } from '@stripe/stripe-js';
import { api } from '../../../../config';
import { hasAuthTokenAsync } from '../../../../lib';
import { formatUSD } from '../../../../lib/format-numbers';
import history from '../../../../lib/history';
import stripeService from '../../../third-party/stripe/redux/stripe-service';
import moment from 'moment';
import WrappedStripePayment from '../../../third-party/stripe/StripeMain/stripe';
import Cookies from 'js-cookie';

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    // this.
  }

  // get inactive() {
  //   const rep = this.state.import > 0 ? this.state.import : 0
  //   return !rep 
  // }
  state = { 
    type: '',
    card: '',
    exp: 'MM/YYYY',
    cvc: 0,
    country: 'US',
    currency: 'usd',
    accountHolder: '',
    accountType: 'individual',
    routingNumber: 0,
    accountNumber: 0,
    bank: []
   }

   componentDidMount() {
    const { user } = Cookies.get('me')?JSON.parse(Cookies.get('me')):{ user: '' };
    Array.isArray(user) && user.map(uData => {
      const {
        id,
        phone
      } = uData
      hasAuthTokenAsync()
      .then(() => {
        api.get(`/accounts/bank/${id}`)
        .then(data => this.setState({ bank: data }))
        .catch(err => err);
      }).catch(err => err);
    })
  }


  get options() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'card',
        display: 'Card',
      },,
      {
        value: 'bank',
        display: 'Bank Account',
      },
    ]
  }

  get accountTypes() {
    return [
      {
        value: '',
        display: 'Select',
      },
      {
        value: 'checking',
        display: 'Checking Account',
      },
      {
        value: 'savings',
        display: 'Savings Account',
      },
    ]
  }

  submit() {
    // e.preventDefault()
    const {
      type,
      exp,
      cvc,
      country,
      currency,
      accountHolder,
      accountType,
      routingNumber,
      accountNumber
    } = this.state
    const pm = sessionStorage.getItem('smp') ? JSON.parse(sessionStorage.getItem('smp')) : '';
    // attach stripe pm to customer
    const {
      billing_details, // object
      card, //last 4
      created,
      customer,
      livemode,
      object,
      // call id directly
    } = pm;
    const me = JSON.parse(cookies.get('me'))
    const {
      id,
      phone,
      email,
      displayName,
      active
    } = me.user[0]
    if (type==='card') {
      const exps = exp.split('/')
      const data = {
        type,
        country,
        currency,
        accountHolder,
        me: {
          id,
          phone,
          email,
          displayName,
          active
        },
        institution: sessionStorage.getItem('institution'),
        accountName: sessionStorage.getItem('account-name'),
        paymentMethod: pm
      }
      Array.isArray(this.state.bank)?
      this.props.patchAccountById(data)
      :this.props.createAccount(data);
      this.props.onClickAway()
    }
    if (type==='bank') {
      const data = {
        type,
        country,
        currency,
        accountHolder,
        me: {
          id,
          phone,
          email,
          displayName,
          active
        },
        institution: sessionStorage.getItem('institution'),
        accountName: sessionStorage.getItem('account-name'),
        paymentMethod: {
          country,
          currency,
          account_holer_name: accountHolder,
          account_type: accountType,
          account_holder_type: 'company',
          routing_number: routingNumber,
          account_number: accountNumber
        }
      }
      Array.isArray(this.state.bank)?
      this.props.patchAccountById(data)
      :this.props.createAccount(data);
      this.props.onClickAway()
    }
  }

  render() {
    const {
      content,
      onClickAway,
      loading
    } = this.props
    const {
      type,
      card,
      exp,
      cvc,
      accountHolder,
      routingNumber,
      accountNumber
    } = this.state

    const invalid = (
      !type
      || type === 'card' 
      ? !card 
      || !exp 
      || !cvc
      :type === 'bank' 
      && !accountNumber 
      || !routingNumber 
      || !accountHolder
    )

    // const CLIENT_SECRET = "pk_test_51Nc1B2K3O6lDYuYKlVXouO1IZQAEJR3vqmnCztkdmjPSyA9NuZsX6vSkDcp3DQOumSRS8omRYyQwkniQZvvBuax700HkcO1oUx"
    const appearance = {
      theme: 'night',
      labels: 'floating'
    };
    const options = {
      layout: {
        paymentMethodTypes: ['klarna', 'afterpay_clearpay', 'affirm'],
        type: 'accordion',
        defaultCollapsed: false,
        radios: false,
        spacedAccordionItems: true,
      }
    };
    // const clientSecret = {CLIENT_SECRET};
    const cancel = (e) => { 
      e.preventDefault();
      window.location.pathname = '/bank'
    }
    const cardNumber = (numberString) => {
      let ns = numberString.split(" ").join(""); 
      let card = ns.length > 0 
      && ns.match(new RegExp('.{1,4}', 'g')).join(" ");
      return card;
    }

    const expFormat = (value, index) => {      
      return value.substring(0, index) + "/" + value.substring(index);
    }

    const totalPrice = sessionStorage.getItem('total-price') ? sessionStorage.getItem('total-price') : ''
    const tier = sessionStorage.getItem('tier') ? JSON.parse(sessionStorage.getItem('tier')) : ''
    const paymentTier = sessionStorage.getItem('payment-tier')
    return (
      <FormModal
        onClickAway={onClickAway}
        visible={content}
        className={styles.modal}
      >
        <label>
          Type:
        </label>
        <Selector
          wide
          value={this.state.type}
          onSelect={i => this.setState({ type: i })}
          options={this.options}
        /> 
        {/* <div className={styles.tier}>
          <h7>Total: {formatUSD(Number(totalPrice)*100)}</h7>
          <h7>Tier: {tier.tier.name}</h7>
          <h7>Additions: {tier.totalAdditions}</h7>
        </div> */}
        {type==='card' ? (
          <>
            <br />
          <WrappedStripePayment onSubmit={this.submit.bind(this)} />
          {/* <label>
              Card details
            </label>
            <Input
              placeholder="XXXX XXXX XXXX XXXX"
              value={cardNumber(card)}
              onChange={card => this.setState({ card })}
              maxlength="19"
            />

            <br />

            <label>
              Expiration date
            </label>
            <Input
              placeholder="MM/YYYY"
              value={exp}
              onChange={exp => this.setState({ exp })}
              maxlength="7"
            />

            <br />
            <label>
              CVC
            </label>
            <Input
              placeholder="123"
              value={cvc}
              onChange={cvc => this.setState({ cvc })}
              maxlength="3"
            /> */}
          </>  
        ) : type==='bank' && (
          <div>
            <br />
            <label>
              Account Holder Name:
            </label>
            <Input
              placeholder="Full Name"
              value={accountHolder}
              onChange={accountHolder => this.setState({ accountHolder })}
            />

            <br />

            <label>
              Routing Number:
            </label>
            <Input
              placeholder="9 digits"
              value={routingNumber}
              onChange={routingNumber => this.setState({ routingNumber })}
              maxlength="9"
            />

            <br />
            <label>
              Account Number:
            </label>
            <Input
              placeholder="account #"
              value={accountNumber}
              onChange={accountNumber => this.setState({ accountNumber })}
              maxlength="20"
            />
            
            <br />
            <label>
              Bank Type:
            </label>
            <Selector
              wide
              value={this.state.accountType}
              onSelect={i => this.setState({ accountType: i })}
              options={this.accountTypes}
            /> 
            <div className="buttons">
              <Button
                large
                cancel
                type="button"
                title="Cancel"
                onClick={cancel}
              />
              <Button
                large
                title="Add Payment Method"
                inactive={invalid}
                type="button"
                onClick={this.submit.bind(this)}
              />
            </div>
          </div>
        )}
        <br />
      </FormModal>
    )
  }
}
 
const mapStateToProps = ({ accountReducer }) => {
  const {
    loading,
    progress
  } = accountReducer

  return {
    loading,
    progress
  }
}

const actions = {
  createAccount,
  patchAccountById
}

export default connect(mapStateToProps, actions)(PaymentPage);