import React, { Component } from 'react';
import { connect } from 'react-redux'
import validator from 'validator'
import cookies from 'js-cookie';

import {
  Selector,
  Button,
  Header,
  Body,
  Input,
  Form,
} from '../../../common'

// Actions
// import {
//   getEmployees,
// } from '../../../../actions/main'

// import {
//   getSupervisors,
// } from '../../../../actions/supervisors'

// import {
//   createAccount,
// } from '../../../../actions/accounts'

import styles from './account-form.css'
// import { hasAuthTokenAsync } from '../../../../lib';

class AddAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: '',
      user: '',
      accessToken: '',
      item: '',
      institutionId: '',
      institution: '',
      accountName: '',
      accountSubtype: '',
      me: cookies.get('me') && JSON.parse(cookies.get('me')) ? JSON.parse(cookies.get('me')) : undefined 
    }
  }

  onSubmit(e) {
    e.preventDefault()
    sessionStorage.setItem('institution', this.state.institution);
    sessionStorage.setItem('account-name', this.state.accountName);
    // console.log('onSubmit', data)
    // this.props.createAccount(data)
  }

  // componentDidMount() {
  //   hasAuthTokenAsync()
  //     .then(() => {
  //       // this.props.getSelf()
  //     })
  //     .catch(console.log)
  // }

  render() {
    const {
      paymentMethod,
      accessToken,
      item,
      institutionId,
      institution,
      accountName,
      accountType,
      accountSubtype,
    } = this.state

    const {
      onViewModal
    } = this.props

    const invalid = (
      !accountName
      || !institution
    )
    return (
      <div>
        <Body className="body">
        <h3>Add Payment Method</h3>
          <Form
            className="form"
            onSubmit={this.onSubmit.bind(this)}
          >
            <br />
            <label>
              Account Name:
            </label>
            <Input
              placeholder="Bank Account 1"
              value={accountName}
              onChange={accountName => this.setState({ accountName })}
            />
            <br />
            <label>
              Institution:
            </label>
            <Input
              placeholder="U.S. Bank, Fidelity Investments, J.P. Morgan/Chase...."
              value={institution}
              onChange={institution => this.setState({ institution })}
            />
            <br />
            {/* {this.props.payment.type} */}

            <br />

            <div className="buttons">
              <Button
                large
                cancel
                link="/bank"
                title="CANCEL"
              />
              <Button
                alt
                type="submit"
                icon="plus"
                inactive={invalid}
                title="ADD PAYMENT METHOD"
                onClick={onViewModal}
              />
            </div>
          </Form>
        </Body>
      </div>
    );
  }
}

const mapStateToProps = ({ main, supervisors }) => {
  // const {
  //   loading,
  //   employeesDisplay
  // } = main
  
  // const {
  //   supervisorsDisplay
  // } = supervisors

  // return {
  //   loading,
  //   employeesDisplay,
  //   superDisplay: supervisorsDisplay
  // }
}

const actions = {
  // createAccount,
  // getEmployees,
  // getSupervisors
}

// export default connect(mapStateToProps, actions)(AddAccount)
export default AddAccount;
