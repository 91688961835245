import React, { Component } from 'react';
import { Container, Grid, Paper, TextField, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Checkbox, Button } from '@mui/material';

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: '',
      email: '',
      phoneNumber: '',
      jobTitle: '',
      resumeLink: '',
      coverLetter: '',
      experience: '',
      skills: [],
      willingToRelocate: false,
      availability: 'fullTime'
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSkillsChange = (event) => {
    const { skills } = this.state;
    const { value } = event.target;

    if (skills.includes(value)) {
      this.setState({ skills: skills.filter(skill => skill !== value) });
    } else {
      this.setState({ skills: [...skills, value] });
    }
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // Submit the application data to the server or perform any necessary action
    console.log(this.state);
  };

  render() {
    const { fullName, email, phoneNumber, jobTitle, resumeLink, coverLetter, experience, skills, willingToRelocate, availability } = this.state;

    return (
      <Container maxWidth="sm" className="application">
        <h1>Job/Gig Application</h1>
        <Paper className="form-container" elevation={3}>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Full Name"
                  variant="outlined"
                  name="fullName"
                  value={fullName}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Job Title"
                  variant="outlined"
                  name="jobTitle"
                  value={jobTitle}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Resume Link"
                  variant="outlined"
                  name="resumeLink"
                  value={resumeLink}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cover Letter"
                  variant="outlined"
                  name="coverLetter"
                  value={coverLetter}
                  onChange={this.handleChange}
                  required
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Experience"
                  variant="outlined"
                  name="experience"
                  value={experience}
                  onChange={this.handleChange}
                  required
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Skills</FormLabel>
                  <FormControlLabel
                    control={<Checkbox checked={skills.includes('javascript')} onChange={this.handleSkillsChange} name="javascript" />}
                    label="JavaScript"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={skills.includes('react')} onChange={this.handleSkillsChange} name="react" />}
                    label="React"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={skills.includes('nodejs')} onChange={this.handleSkillsChange} name="nodejs" />}
                    label="Node.js"
                  />
                  {/* Add more skills checkboxes here */}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={willingToRelocate} onChange={this.handleCheckboxChange} name="willingToRelocate" />}
                  label="Willing to Relocate"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Availability</FormLabel>
                  <RadioGroup name="availability" value={availability} onChange={this.handleChange}>
                    <FormControlLabel value="fullTime" control={<Radio />} label="Full Time" />
                    <FormControlLabel value="partTime" control={<Radio />} label="Part Time" />
                    <FormControlLabel value="freelance" control={<Radio />} label="Freelance" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Submit Application
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    );
  }
}

export default Application;
