// Router
import { push } from 'react-router-redux'

// Cookies
import cookies from 'js-cookie'

// Decrypt
import jwt from 'jsonwebtoken';

// Types
import * as signinTypes from './signin-types'

// Services
import signin from './signin-service'
import { localstorage } from '../../../shared';

const {
  CREATE_COMPANY,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_SUCCESS,
  SET_COMPANY,
  GET_COMPANY,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  PATCH_COMPANY,
  PATCH_COMPANY_FAILURE,
  PATCH_COMPANY_SUCCESS,
  EMAIL_CHANGED,
  ACCESS_CHANGED,
  USERNAME_CHANGED,
  PASSWORD_CHANGED,
  CONFIRM_PASSWORD_CHANGED,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  GET_SELF,
  GET_SELF_FAILURE,
  GET_SELF_SUCCESS,
  PATCH_SELF,
  PATCH_SELF_FAILURE,
  PATCH_SELF_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CREATE_PASSWORD,
  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_FAILURE,
  CLAIM_ACCOUNT,
  CREATE_PASSWORD_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CLEAR_ERRORS,
  CLEAR_STATE,
} = signinTypes

const me = cookies.get('me') ? JSON.parse(cookies.get('me')) : {}

export const createCompany = payload => dispatch => {
  dispatch({
    type: CREATE_COMPANY,
  })
  signin.register(payload)
    .then(res => onCreateCompanySuccess({dispatch, res}))
    .catch(err => onCreateCompanyFailure({dispatch, err}))
}

function onCreateCompanySuccess({dispatch, res}) {
  console.log('createCompany success, ', res);
  localStorage.removeItem("email");
  localStorage.removeItem("portal");
  localStorage.removeItem("password");
  localStorage.removeItem("phone");
  dispatch({
    type: CREATE_COMPANY_SUCCESS,
  })
  window.alert('Verification Success!');
  window.location.pathname = '/signin';
  // push('/signin');
}

function onCreateCompanyFailure({dispatch, err}) {
  console.log('createCompany failure', err);
  const payload = err.message || 'CreateCompany Failed'
  window.alert(payload)
  dispatch({
    type: CREATE_COMPANY_FAILURE,
    payload,
  })
}

export const sendSMS = payload => dispatch => {
  const phone = localStorage.getItem('phone')
  dispatch({
    type: SEND_SMS,
  })
  signin.sendSMS({ phoneNumber: phone })
    .then(res => onSendSMSSuccess({dispatch, res}))
    .catch(err => onSendSMSFailure({dispatch, err}))
}

function onSendSMSSuccess({dispatch, res}) {
  console.log('SendSMS success ,', res);
  dispatch({
    type: SEND_SMS_SUCCESS,
  })
}

function onSendSMSFailure({dispatch, err}) {
  console.log('SendSMS failure', err);
  const payload = err.message || 'SendSMS Failed'
  window.alert(payload)
  dispatch({
    type: SEND_SMS_FAILURE,
    payload,
  })
}

export const setCompany = payload => {
  return {
    type: SET_COMPANY,
    payload,
  }
}

export const getCompanyById = companyId => (dispatch, getState) => {
  // check if a company has been set yet
  const { company } = getState().signin
  if (company && company.id !== companyId) {
    // clear it if it is not the same one
    console.log('clearing state');
    dispatch({
      type: CLEAR_STATE,
    })
  }

  dispatch({
    type: GET_COMPANY,
  })
  signin.getById(companyId)
    .then(res => onGetCompanySuccess({dispatch, res}))
    .catch(err => onGetCompanyFailure({dispatch, err}))
}

function onGetCompanySuccess({dispatch, res}) {
  console.log('getCompany success');
  dispatch({
    type: GET_COMPANY_SUCCESS,
    payload: res.company,
  })
}

function onGetCompanyFailure({dispatch, err}) {
  console.log('getCompany failure', err);
  const payload = err.message || 'GetCompany Failed'
  window.alert(payload)
  dispatch({
    type: GET_COMPANY_FAILURE,
    payload,
  })
  dispatch(push('/login'))
}

export const patchCompanyById = (id, update) => (dispatch, getState) => {
  dispatch({
    type: PATCH_COMPANY,
  })
  signin.patchById(id, update)
    .then(res => onPatchCompanySuccess({dispatch, res}))
    .catch(err => onPatchCompanyFailure({dispatch, err}))
}

function onPatchCompanySuccess({dispatch, res}) {
  console.log('patchCompany success');
  dispatch({
    type: PATCH_COMPANY_SUCCESS,
    payload: res.company,
  })
}

function onPatchCompanyFailure({dispatch, err}) {
  console.log('patchCompany failure', err)
  const payload = err.message || 'PatchCompany Failed'
  window.alert(payload)
  dispatch({
    type: PATCH_COMPANY_FAILURE,
    payload,
  })
}

// Forms
export const usernameChanged = payload => {
  return {
    type: USERNAME_CHANGED,
    payload,
  }
}

export const emailChanged = payload => {
  return {
    type: EMAIL_CHANGED,
    payload,
  }
}

export const accessChanged = payload => {
  return {
    type: ACCESS_CHANGED,
    payload,
  }
}

export const passwordChanged = payload => {
  return {
    type: PASSWORD_CHANGED,
    payload,
  }
}

export const passwordConfirmChanged = payload => {
  return {
    type: CONFIRM_PASSWORD_CHANGED,
    payload,
  }
}

export const login = payload => dispatch => {
  dispatch({
    type: LOGIN,
  })
  signin.login(payload)
  .then(res => onLoginSuccess({dispatch, res}))
  .catch(res => onLoginFailure({dispatch, res}))
}

function onLoginSuccess({dispatch, res}) {
  console.log('login success');
  dispatch({
    type: LOGIN_SUCCESS,
  })
  cookies.set('token', res);
  cookies.set('me', JSON.stringify(jwt.verify(res, 'F5Yk!4N(3')), { expires: 14 });
  // dispatch(push('/settings'))
  window.location.pathname = '/settings';
}

function onLoginFailure({dispatch, res}) {
  console.log('login failure', res);
  dispatch({
    type: LOGIN_FAILURE,
    payload: 'Invalid Credentials',
  })
}

// Contacts
export const getSelf = () => dispatch => {
  dispatch({
    type: GET_SELF,
    payload: me
  })
}

export const getSelfs = () => dispatch => {
  dispatch({
    type: GET_SELF_SUCCESS,
  })
  Promise.resolve()
    .then(res => onGetSelfSuccess({dispatch, res}))
    .catch(err => onGetSelfFailure({dispatch, err}))
}

function onGetSelfSuccess({dispatch, res}) {
  console.log('getSelf success');
  // const { user } = res
  dispatch({
    type: GET_SELF_SUCCESS,
    payload: me,
  })
}

function onGetSelfFailure({dispatch, err}) {
  const message = err.message || 'Get Self Failed'
  console.log('getSelf failure', message);
  window.alert(message)
  dispatch({
    type: GET_SELF_FAILURE,
    payload: message,
  })
}

export const resetPassword = ({ email }) => dispatch => {
  dispatch({
    type: RESET_PASSWORD,
  })
  signin.resetPassword({ email })
    .then(res => onResetPasswordSuccess({dispatch, res}))
    .catch(err => onResetPasswordFailure({dispatch, err}))
}

function onResetPasswordSuccess({dispatch, res}) {
  console.log('resetPassword success');
  dispatch({
    type: RESET_PASSWORD_SUCCESS,
  })
  dispatch(push('/login'))
}

function onResetPasswordFailure({dispatch, err}) {
  const message = err.message || 'Reset Password Failed'
  console.log('resetPassword failure', message);
  window.alert(message)
  dispatch({
    type: RESET_PASSWORD_FAILURE,
    payload: message,
  })
}

export const createPassword = data => dispatch => {
  dispatch({
    type: CREATE_PASSWORD,
  })
  signin.createPassword(data)
    .then(res => onCreatePasswordSuccess({dispatch, res}))
    .catch(err => onCreatePasswordFailure({dispatch, err}))
}

export const claimAccount = data => dispatch => {
  dispatch({
    type: CLAIM_ACCOUNT,
  })
  signin.claimAccount(data)
    .then(res => onCreatePasswordSuccess({dispatch, res}))
    .catch(err => onCreatePasswordFailure({dispatch, err}))
}

function onCreatePasswordSuccess({dispatch, res}) {
  console.log('createPassword success');
  dispatch({
    type: CREATE_PASSWORD_SUCCESS,
  })

  // set new token for Session Storage
  cookies.set('token', res.token)

  dispatch(push('/'))
}

function onCreatePasswordFailure({dispatch, err}) {
  const message = err.message || 'Create Password Failed'
  console.log('createPassword failure', message);
  window.alert(message)
  dispatch({
    type: CREATE_PASSWORD_FAILURE,
    payload: message,
  })
}

export const patchSelf = update => dispatch => {
  dispatch({
    type: PATCH_SELF,
  })
  signin.patchSelf(update)
    .then(res => onPatchSelfSuccess({dispatch, res}))
    .catch(err => onPatchSelfFailure({dispatch, err}))
}

function onPatchSelfSuccess({dispatch, res}) {
  console.log('patchSelf success');
  const { user } = res
  dispatch({
    type: PATCH_SELF_SUCCESS,
    payload: user,
  })
}

function onPatchSelfFailure({dispatch, err}) {
  const message = err.message || 'Patch Self Failed'
  console.log('patchSelf failure', message);
  window.alert(message)
  dispatch({
    type: PATCH_SELF_FAILURE,
    payload: message,
  })
}

export const logout = () => dispatch => {
  if (window.confirm('Log out?')) {
    localStorage.clear()
    sessionStorage.clear()
    cookies.remove('token');
    cookies.remove('me');
    window.location.pathname = '/login';
    // dispatch(push('/login'))
  }
}

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  }
}

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  }
}
